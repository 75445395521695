import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {Link} from "react-router-dom";

const GroupBubbleTitle = ({ group, colorPalette, hexToShadow }) => {

    return (
        <Grid
            className={`chat-bubble`}
            sx={{
                boxShadow: hexToShadow(colorPalette.shadow),
            }}
        >
            <Box
                className="article_box_header_link"
                bgcolor={colorPalette.paper}
            >
                <Typography variant={"h6"}>{group.title}</Typography>
            </Box>
            <Box className="article_box_meta" bgcolor={colorPalette.dark}>
                <Grid container>
                    <Grid item sx={{ flex: 1}} align={"right"}>
                        <Button
                            variant="outlined"
                            component={Link}
                            color={"info"}
                            to={`/view/manager/${group.id}`}
                            sx={{ ml: 2, mb: 2 }}
                            endIcon={<KeyboardArrowRightIcon/>}
                        >
                            Kiválaszt
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );

};

export default GroupBubbleTitle;
