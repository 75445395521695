import axios from "axios"
import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';

const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Name', width: 130 },
    { field: 'email', headerName: 'Email', width: 130 },
    { field: 'googleId', headerName: 'Google ID', width: 130 },
    { field: 'created_at', headerName: 'Created At', width: 150 },
    { field: 'updated_at', headerName: 'Updated At', width: 150 },
    { field: 'subscribed', headerName: 'Subscribed', width: 130 },
    { field: 'subscribed_at', headerName: 'Subscribed At', width: 130 },
    { field: 'subscription_count', headerName: 'S. Count', width: 100 },
    { field: 'rights', headerName: 'Rights', width: 70 },
    { field: 'state', headerName: 'State', width: 70 },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 130,
        renderCell: (params) => (
            <>
                {/*<Link to={`user/${params.row.id}/edit`} style={{marginRight: "10px"}}>Edit</Link>*/}
                {/*<button onClick={() => deleteUser(params.row.id)}>Delete</button>*/}
            </>
        ),
    },
    ];

    export default function ListUser()
    {
        const [users, setUsers] = useState([]);

        useEffect(() => {
            getUsers();
        }, []);

        function getUsers()
        {
            axios.get('/api/list/users').then(function (response) {
                setUsers(response.data);
            });
        }

        const deleteUser = (id) => {
            axios.delete(`/api/user/${id}/delete`).then(function (response) {
                getUsers();
            });
        }

        return (
        <div style={{ height: '100%', width: '100%' }}>
            <DataGrid
                rows={users}
                columns={columns}
                pageSizeOptions={[5, 10]}
                checkboxSelection
            />
        </div>
        )
    }
