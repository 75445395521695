import React from 'react';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import CreateGroup from "../CreateGroup";

const GroupsMenuBar = ({ colorPalette, hexToShadow}) => {
    const [createGroup, setCreateGroup] = React.useState(false);

    const toggleCreateGroup = () => {
        setCreateGroup(!createGroup);
    }

    return (
        <Box
            className={'animated-resize-height'}
            sx={{
                boxShadow: hexToShadow(colorPalette.sidebar),
                height: createGroup ? "250px" : "70px",
            }}
        >
            <Button
                color={"info"}
                variant={createGroup ? "contained" : "outlined"}
                sx={{ ml: 2, mr: 2, mb: 2, mt: 2 }}
                onClick={() => toggleCreateGroup()}
                endIcon={<AddIcon sx={{ ml: 2 }}/>}
            >
                Új csoport
            </Button>

            {createGroup && <CreateGroup />}
        </Box>
    );

};

export default GroupsMenuBar;
