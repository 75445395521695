import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const UserSearchField = ({ users, selectedUser, setSelectedUser }) => {
    const usersArray = Object.values(users);

    return (
        <Autocomplete
            defaultValue={selectedUser}
            onChange={(event, newValue) => {
                setSelectedUser(newValue);
            }}
            options={usersArray}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
                <TextField {...params} key={params.id} label="Felhasználónév" variant="outlined" />
            )}
        />
    );
};

export default UserSearchField;
