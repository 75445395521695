import React from 'react';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Typography from "@mui/material/Typography";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import {Tooltip} from "@mui/material";
import {color} from "@mui/system";

const ShowBubbleContent = ({ content, title, testId = 0, colorPalette, setTextToSpeech, hexToShadow }) => {

    function calculateReadTime(content, wordsPerMinute = 130) {
        if (!content) return 0;
        const words = content.split(/\s+/);
        const wordCount = words.length;
        return Math.ceil(wordCount / wordsPerMinute);
    }

    return (
        <Box
            className={`sidebar-profile-container`}
            sx={{ boxShadow: hexToShadow(colorPalette.paper) }}
        >
            <Box bgcolor={colorPalette.dark}>
                <Grid container flex>
                    <Grid item align={"left"} sx={{ mt: 2, ml: 2 }} flex={1}>
                        <Typography variant="h6" color={colorPalette.text_on_dark}>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item align={"right"} sx={{ mt: 2, mr: 2, mb: 2 }} flex={1} color={colorPalette.text_on_dark}>
                        <Tooltip title="Olvasási idő" arrow>
                            <React.Fragment>
                                <AutoStoriesIcon sx={{ mr: 1}}  /> {calculateReadTime(content)} perc
                            </React.Fragment>
                        </Tooltip>
                        <Button color="info" onClick={() => (setTextToSpeech(content))}>
                            <VolumeUpIcon />
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Box className="article_box_header_link" bgcolor={colorPalette.secondary} color={colorPalette.text_on_secondary}>
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </Box>
                <Grid container flex bgcolor={colorPalette.dark}>
                    <Grid item align={"left"} sx={{ mt: 2, ml: 2, mb: 2 }} flex={1}>
                        <Typography variant="h6" color={colorPalette.text_on_dark}>
                        {title}
                        </Typography>
                    </Grid>
                    {testId !== 0 && (
                        <Grid item xs={6} align={"right"} flex={1}>
                            <Button
                                variant="outlined"
                                color={"success"}
                                sx={{ mr: 2, mt: 2, mb: 2 }}
                                onClick={() => (window.location.href = `/test/${testId}`)}
                            >
                                Teszt <KeyboardArrowRightIcon/>
                            </Button>
                        </Grid>
                    )}
                </Grid>
        </Box>
    );

};

export default ShowBubbleContent;
