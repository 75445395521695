import React from 'react';
import Box from '@mui/material/Box';
import {useSessionData} from "../../../auth/SessionDataProvider";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Grid from "@mui/material/Grid";
import {IconButton, Tooltip} from "@mui/material";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";

const TaskCard = ({ order, colorPalette, selectedOrder, setSelectedOrder }) => {
    const { isOwner, hexToShadow, sessionData, toggleSelectedUser, isSelectedUser } = useSessionData();

    return (
        <Grid
            container
            className={`chat-bubble`}
            sx={{
                boxShadow: hexToShadow(colorPalette.shadow),
                ml: 2, mr: 2, mt: 1, mb: 1,
            }}
        >
            <Box
                item flex={11}
                className="chat-bubble-content"
                bgcolor={selectedOrder.id === order.id ? colorPalette.success : colorPalette.paper}
                color={colorPalette.text}
                sx={{
                    padding: '20px',
                    textAlign: 'center',
                }}
            >
                {order.title}
            </Box>
            <Box
                item flex={1}
                className="chat-bubble-content"
                bgcolor={colorPalette.paper}
                color={colorPalette.text}
                sx={{ paddingTop: '10px' }}
            >
                <Tooltip title="Kiválasztás" arrow>
                    <IconButton onClick={() => setSelectedOrder(order)} color={"success"}>
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        </Grid>
    );

};

export default TaskCard;
