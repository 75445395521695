import axios from "axios"
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SendMessage from "./SendMessage";
import Button from "@mui/material/Button";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import EditField from "../interface/EditField";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export default function ViewMessage({ id, singleUnit = false, setTextToSpeech })
{
    const [messageId, setMessageId] = useState(id);
    const [message, setMessage] = useState([]);
    const [color, setColor] = useState('primary');
    const [isEditing, setIsEditing] = useState(false);
    const [messageContent, setMessageContent] = useState('');

    useEffect(() => {
        getMessage();
    }, [messageId]);

    function getMessage() {
        axios
            .get(`/api/get/message/${messageId}`)
            .then(function (response) {
                setMessage(response.data);
                setMessageContent(response.data.content);
                setColor(response.data.state === 0 ? 'primary' : 'secondary');
            })
            .catch(function (error) {
                console.error('Error fetching data:', error);
            });
    }

    return (
        <>
        <Box
            className={`chat-bubble`}
            sx={{
                marginLeft: message.is_owner ? "auto" : "initial",
                marginRight: message.is_owner ? "initial" : "auto",
            }}
        >
            <Box className="article_box_header_link" bgcolor={message.is_owner ? "success.light" : "info.main"}>
                {isEditing === true ? (
                    <EditField
                        fieldType={'message'}
                        fieldId={message.id}
                        content={messageContent}
                        setContent={setMessageContent}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                    />
                ) : (
                    <>
                        <div dangerouslySetInnerHTML={{ __html: messageContent }}></div>
                    </>
                )}
            </Box>
            <Box className="article_box_meta">
                <Grid container>
                    <Grid item xs={3}>
                        <Box className="article_box_author_link">{message.user_from_name}</Box>
                        <Box className="article_box_time">
                            <Grid container spacing={1}>
                                <Grid item>
                                    {message.date}
                                </Grid>
                                <Grid item>
                                    {message.read === 0 ? (
                                        <> Elküldve </>
                                    ) : (
                                        <> Olvasva </>
                                    )}
                                </Grid>
                                <Grid item>
                                    {message.edited === 1 && " Szerkesztve " }
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Button color="info" onClick={() => (setTextToSpeech(message.content))}>
                            <VolumeUpIcon />
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        {message.replied > 0 && (
                            <Button
                                variant="outlined"
                                color="success"
                                disabled={isEditing === true}
                                onClick={() => {
                                    window.location.href = `/view/message/${message.replied}`;
                                }}
                            >
                                <CheckCircleIcon /> Megválaszolva!
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={3} align={"right"}>
                        {message.is_owner === true && (
                            <Button
                                sx={{ ml: 2, mb: 2, mt: 2, mr: 2 }}
                                variant="outlined"
                                color={isEditing === true ? 'error' : 'info'}
                                onClick={() => setIsEditing(!isEditing)}
                                endIcon={<EditIcon sx={{ mr: 2 }}/>}
                            >
                                Szerkesztés
                            </Button>
                        )}
                        <Button
                            variant="outlined"
                            color={"success"}
                            disabled={isEditing === true}
                            sx={{ ml: 2 }}
                            onClick={() => (window.location.href = `/view/message/${message.id}`)}
                        >
                            Megnyit <KeyboardArrowRightIcon/>
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <SendMessage userTo={message.user_to} userFrom={message.user_from} parentId={message.id} />
        </Box>
    </>
    );
}
