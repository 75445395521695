import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import dayjs from 'dayjs';
import 'dayjs/locale/hu';

dayjs.locale('hu');

export default function Calendar({ colorPalette }) {
    const customLocale = {
        options: {
            weekStartsOn: 1,
        },
    };

    return (
        <Box
            className={`sidebar-profile-container`}
            bgcolor={colorPalette.secondary}
            sx={{ mb: 2 }}
        >
            <LocalizationProvider dateAdapter={AdapterDayjs} locale={customLocale}>
                <DateCalendar sx={{ pt: 2 }} />
            </LocalizationProvider>
            <Box className="chat-bubble-meta" bgcolor={colorPalette.dark}>
                <Typography variant={"h6"} color={colorPalette.text_on_dark}><CalendarMonthIcon sx={{ mr: 2 }}/> Naptár</Typography>
            </Box>
        </Box>
    );
}
