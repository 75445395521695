import React, {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import axios from "axios";
import {Chip, CircularProgress, Paper} from "@mui/material";
import Divider from "@mui/material/Divider";
import {useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import {PieChart} from "@mui/x-charts/PieChart";
import PieChartWithPaddingAngle from "../../util/PieChartWithPaddingAngle";
import QuestionWithAnswers from "./QuestionWithAnswers";
import ViewTestQuestionResults from "./ViewTestQuestionResults";
import Grid from "@mui/material/Grid";
import {useSessionData} from "../../auth/SessionDataProvider";

const ViewTest = ({ colorPalette }) => {
    const [testData, setTestData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pieChartData, setPieChartData] = useState([]);
    const { id } = useParams();
    const { hexToShadow } = useSessionData();

    useEffect(() => {
        getTestEvaluation();
    }, []);

    function getTestEvaluation() {
        axios
            .get(`/api/get/testResult/${id}`)
            .then(function (response) {
                setTestData(response.data);
                console.log(response.data);
                const newPieChartData = response.data.question?.map((item, index) => ({
                    label: `${index + 1}. Kérdés`,
                    value: item.answer_time,
                    color: item.good_answer ? '#4caf50' : '#ff3366'
                }));
                setPieChartData(newPieChartData);
                setLoading(false);
            })
            .catch(function (error) {
                console.error('Error fetching data:', error);
            });
    }

    if (loading) {
        return (
                <CircularProgress />
        );
    }

    return (
        <>
            <Box sx={{ backgroundColor: colorPalette.paper, boxShadow: hexToShadow(colorPalette.shadow), mt: 2, mb: 3 }}>
                <Box className="chat-bubble-content" bgcolor={colorPalette.paper} sx={{ color: colorPalette.text, padding: '20px' }}>
                    <Grid container align={"center"} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <Grid item flex={1} align={"center"}>
                            <Chip
                                label={
                                    <Typography
                                        className="test-result-bigtext"
                                        sx={{ fontSize: '2rem' }}
                                    >
                                        {testData.result_percent}%
                                    </Typography>
                                }
                                color={testData.result_percent >= testData.success_percent ? "success" : "error"}
                                className="test-result-big"
                                size="large"
                                sx={{
                                    width: '150px',
                                    height: '150px',
                                    borderRadius: '50%',
                                    border: '7px solid #1a1a1a',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    opacity: 0.8,
                                }}
                            >
                            </Chip>
                        </Grid>
                        <Grid item flex={1} align={"center"}>
                            <Typography variant="h4"
                                        sx={{mt: 3, mb: 3}}
                                        color={testData.success ? 'success.main' : 'error'}
                                        sx={{
                                            textShadow: '2px 2px 2px #1a1a1a',
                                            opacity: 0.8,
                                        }}
                            >
                                {testData.success ? 'Sikeres' : 'Sikertelen'}!
                            </Typography>
                        </Grid>
                        <Grid item flex={1} align={"center"}>
                            <PieChartWithPaddingAngle data={pieChartData} />
                        </Grid>
                    </Grid>
                </Box>
                <Box className="chat-bubble-meta" bgcolor={colorPalette.dark} color={colorPalette.text_on_dark}>
                    Eredmény összesítő
                </Box>
            </Box>
            <ViewTestQuestionResults yourDataArray={testData.question} colorPalette={colorPalette}/>
        </>
    );
};

export default ViewTest;
