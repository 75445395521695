import axios from "axios";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TitleBox from "../../interface/TitleBox";
import {AvatarGroup, CircularProgress} from "@mui/material";
import TaskCard from "./TaskCard";
import { useSessionData } from "../../../auth/SessionDataProvider";
import StringAvatar from "../../../user/StringAvatar";
import Typography from "@mui/material/Typography";
import CreateTask from "./CreateTask";
import UserAvatar from "../../../user/UserAvatar";

const ProgressTree = ({ colorPalette, groupId }) => {
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { isOnPath, hexToShadow, sessionData } = useSessionData();
    const [selectedUsersProgress, setSelectedUsersProgress] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(0);
    const [notFoundUsers, setNotFoundUsers] = useState([]);

    useEffect(() => {
        getOrder();
        getSelectedUsersProgress();
        setNotFoundUsers([]);
    }, [groupId]);

    function getOrder() {
        axios.get(`/api/group/order/${groupId}`).then(function (response) {
            console.log(response.data);
            setOrders(response.data);
            setIsLoading(false);
        });
    }

    const getSelectedUsersProgress = () => {
        const formData = new FormData();
        formData.append('selected_users', sessionData.selected_users);
        formData.append('group_id', groupId);
        axios.post('/api/group/selectedUsersProgress', formData)
            .then(function (response) {
                console.log(response.data);
                setSelectedUsersProgress(response.data);
            });
    };

    if (isLoading) {
        return <CircularProgress size={20} sx={{ ml: 2 }} />;
    }

    return (
        <>
            <Grid item flex={1} hidden={selectedOrder === 0} align={"center"}>
                <CreateTask groupId={groupId} selectedUsers={notFoundUsers} selectedOrder={selectedOrder} />
            </Grid>
            <TitleBox title={"Csoport áttekintő"} />
            <Grid container flex={1}>
                <Grid item flex={1}>
                    <AvatarGroup sx={{ justifyContent: 'center' }}>
                        <StringAvatar
                            userName={"Nincs hozzárendelve"}
                            bgColor={colorPalette.error}
                        />
                        <StringAvatar
                            userName={"Folyamatban"}
                            bgColor={colorPalette.warning}
                        />
                    </AvatarGroup>
                </Grid>
                <Grid item flex={1} align={"center"}>
                    <Typography variant={"h6"} color={colorPalette.text_on_dark}>Lecke</Typography>
                </Grid>
                <Grid item flex={1} align={"center"} sx={{ mb: 3 }}>
                    <StringAvatar
                        userName={"Kész"}
                        bgColor={colorPalette.success}
                    />
                </Grid>
                {Array.isArray(orders) && orders.length > 0 && (
                    orders.map((order, index) => (
                        <Grid container key={order.id}>
                            <Grid item flex={1} align={"center"}>
                                <AvatarGroup max={8} sx={{ justifyContent: 'center' }}>
                                    {selectedUsersProgress[order.lesson_id] &&
                                        Object.keys(selectedUsersProgress[order.lesson_id]).map((userId) => {
                                            if (selectedUsersProgress[order.lesson_id][userId] === 'not_found') {
                                                notFoundUsers.push(userId);
                                                return (
                                                    <UserAvatar
                                                        key={userId}
                                                        user={sessionData.session_users[userId]}
                                                        bgColor={colorPalette.error}
                                                    />
                                                );
                                            }
                                        })}
                                </AvatarGroup>
                                <AvatarGroup max={8} sx={{ justifyContent: 'center' }}>
                                    {selectedUsersProgress[order.lesson_id] &&
                                        Object.keys(selectedUsersProgress[order.lesson_id]).map((userId) => (
                                            selectedUsersProgress[order.lesson_id][userId] === 'in_progress' && (
                                                <UserAvatar
                                                    key={userId}
                                                    user={sessionData.session_users[userId]}
                                                    bgColor={colorPalette.warning}
                                                />
                                            )
                                        ))}
                                </AvatarGroup>
                            </Grid>
                            <Grid item flex={1} align={"center"}>
                                <TaskCard
                                    order={order}
                                    selectedOrder={selectedOrder}
                                    setSelectedOrder={setSelectedOrder}
                                    colorPalette={colorPalette}
                                />
                            </Grid>
                            <Grid item flex={1}>
                                <AvatarGroup max={8} sx={{ justifyContent: 'center' }}>
                                    {selectedUsersProgress[order.lesson_id] &&
                                        Object.keys(selectedUsersProgress[order.lesson_id]).map((userId) => (
                                            selectedUsersProgress[order.lesson_id][userId] === 'completed' && (
                                                <UserAvatar
                                                    key={userId}
                                                    user={sessionData.session_users[userId]}
                                                    bgColor={colorPalette.success}
                                                />
                                            )
                                        ))}
                                </AvatarGroup>
                            </Grid>
                        </Grid>
                    ))
                )}
            </Grid>
        </>
    );
};

export default ProgressTree;
