import React, {useEffect, useState} from 'react';
import axios from "axios";
import ShowBubbleContent from "../interface/ContentBox/ShowBubbleContent";
import {useParams} from "react-router-dom";
import GroupMenuBar from "./menubar/GroupMenuBar";
import ViewTopics from "./topics/ViewTopics";
import {useSessionData} from "../../auth/SessionDataProvider";

export default function ViewGroup({ setTextToSpeech, colorPalette, hexToShadow, isSidebarVisible })
{
    const { sessionData, setSessionData } = useSessionData();
    const [group, setGroup] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        getGroup();
    }, [id]);

    function getGroup() {
        axios
            .get(`/api/get/group/${id}`)
            .then(function (response) {
                setGroup(response.data);
                // setSessionData({
                //     ...sessionData,
                //     selectedGroup: id,
                // });
            })
            .catch(function (error) {
                console.error('Error fetching data:', error);
            });
    }
    return (
        <>
            <GroupMenuBar groupId={id} colorPalette={colorPalette} hexToShadow={hexToShadow} />
            <ShowBubbleContent content={group.content} title={group.title} colorPalette={colorPalette} setTextToSpeech={setTextToSpeech} hexToShadow={hexToShadow}/>
            <ViewTopics groupId={id} setTextToSpeech={setTextToSpeech} colorPalette={colorPalette} hexToShadow={hexToShadow} isSidebarVisible={isSidebarVisible} />
        </>
    );
}
