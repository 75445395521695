import React, { useState } from 'react';
import Button from "@mui/material/Button";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import axios from "axios";
import Divider from "@mui/material/Divider";
import ShowSnack from "../../../util/ShowSnack";
import {useSessionData} from "../../../auth/SessionDataProvider";

function CreateTask({ groupId, selectedOrder, selectedUsers })
{
    const [snackOpen, setSnackOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const { sessionData } = useSessionData();

    const handleSubmit = async(e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('selected_users', selectedUsers);
        formData.append('group_id', groupId);
        formData.append('lesson_id', selectedOrder.lesson_id);
        formData.append('content', selectedOrder.title);
        formData.append('task_type', 'lesson');
        console.log(formData);
        axios.post('/api/create/task', formData)
            .then(function (response) {
                if (response.data === 1) {
                    setSnackOpen(true);
                    setMessage('Hozzáadva!');
                    setSeverity('success');
                } else {
                    setSnackOpen(true);
                    setMessage('Sikertelen művelet!');
                    setSeverity('error');
                }
            });
    };

    return (
    <>
        <Divider />
        <Container>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Button
                    color={"success"}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 1, mb: 2 }}
                >
                    Feladat Hozzáadás
                </Button>
            </Box>
        </Container>
        <ShowSnack snackOpen={snackOpen} setSnackOpen={setSnackOpen} message={message} severity={severity} />
    </>
    );
}

export default CreateTask;
