import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {Link} from "react-router-dom";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {useSessionData} from "../../../auth/SessionDataProvider";
import EditIcon from "@mui/icons-material/Edit";

const LessonMenuBar = ({ parent, colorPalette }) => {
    const { hasEditRights, isOnPath, hexToShadow } = useSessionData();

    if (hasEditRights(parent)) {
        return (
            <Box
                className="animated-resize-height"
                sx={{
                    boxShadow: hexToShadow(colorPalette.sidebar),
                    height: '70px',
                }}
            >
                <Button
                    color="info"
                    component={Link}
                    to={`/view/lesson/${parent.id}`}
                    variant={isOnPath('view') ? 'contained' : 'outlined'}
                    sx={{ml: 2, mr: 2, mb: 2, mt: 2}}
                    endIcon={<RemoveRedEyeIcon/>}
                >
                    Megtekintés
                </Button>
                <Button
                    color="info"
                    component={Link}
                    to={`/edit/lesson/${parent.id}`}
                    variant={isOnPath('/edit/lesson') ? 'contained' : 'outlined'}
                    sx={{mr: 2, mb: 2, mt: 2}}
                    endIcon={<EditIcon />}
                >
                    Lecke szerkesztés
                </Button>
                <Button
                    color="info"
                    component={Link}
                    to={`/edit/test/${parent.test_id}`}
                    variant={isOnPath('/edit/test') ? 'contained' : 'outlined'}
                    sx={{mr: 2, mb: 2, mt: 2}}
                    endIcon={<EditIcon />}
                >
                    Teszt szerkesztés
                </Button>
            </Box>
        );
    }

    return null;
};

export default LessonMenuBar;
