import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import React, {useEffect, useState} from 'react';
import GoogleSignInButton from "./GoogleSignInButton";

const Login=()=>{
    const [user,setUser]=useState({email:'',password:''})
    const [auth,setAuth]=useState(false);

    useEffect(() => {
        axios.post('/api/user/session')
            .then((result) => {
                if (result.data !== false && result.data.logged === true) {
                    setAuth(result.data.logged);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        window.handleCredentialResponse = function (response) {
            const googleData = JSON.parse(atob(response.credential.split('.')[1]));
            axios.post(`/api/user/google_login`,googleData)
                .then((result)=>{
                    if (result.data !== false && result.data.logged === true) {
                        window.location.href = '/';
                    }
                });
        };
    }, []);

    const handleChange=(e)=>{
        setUser({...user, [e.target.name]: e.target.value});
    }

    const submitForm=(e)=>{
        e.preventDefault();
        const sendData = {
            email:user.email,
            password:user.password
        }
        axios.post('/api/user/login',sendData)
        .then((result)=>{
            if (result.data !== false && result.data.logged === true) {
                window.location.href = '/';
            }
        });
    }

    if (auth===true) {
        return null;
    }

    return(
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5" mt={2}>
                    Sign in
                </Typography>
                <Box component="form" onSubmit={submitForm} noValidate sx={{ mt: 5 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email-input"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={handleChange}
                        value={user.email || ''}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password-input"
                        autoComplete="current-password"
                        onChange={handleChange}
                        value={user.password || ''}
                        mb={5}
                    />
                    <GoogleSignInButton/>
                    <Button
                        type="submit"
                        name="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 5, mb: 2 }}
                    >
                        Sign In
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/register" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    )
}

export default Login;
