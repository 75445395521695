import React, { useState, useEffect } from 'react';
import QuestionWithAnswers from './QuestionWithAnswers';
import {Box} from "@mui/system";
import Grid from "@mui/material/Grid";
import {useSessionData} from "../../auth/SessionDataProvider";

const ViewTestQuestionResults = ({ yourDataArray, colorPalette }) => {
    const [displayedQuestions, setDisplayedQuestions] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            if (currentIndex < yourDataArray.length) {
                setDisplayedQuestions([...displayedQuestions, yourDataArray[currentIndex]]);
                setCurrentIndex(currentIndex + 1);
            } else {
                clearInterval(timer);
            }
        }, 320);

        return () => clearInterval(timer);
    }, [currentIndex, yourDataArray, displayedQuestions]);

    return (
        <Grid container>
            {displayedQuestions.map((questionData, index) => (
                <Grid item xs={6} key={index}>
                    <QuestionWithAnswers
                        questionData={questionData}
                        colorPalette={colorPalette}
                        style={{
                            opacity: 1,
                            transition: 'opacity 0.5s',
                            animation: 'fadeIn 0.5s ease-in-out forwards',
                            animationDelay: `${index * 0.5}s`,
                        }}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default ViewTestQuestionResults;
