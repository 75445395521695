import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {SessionDataProvider} from "./components/auth/SessionDataProvider";

ReactDOM.render(
    <SessionDataProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </SessionDataProvider>,
    document.getElementById('root')
);
