import axios from "axios"
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import ShowFeedTestResults from "./ShowFeedTestResults";
import {useSessionData} from "../../auth/SessionDataProvider";

export default function UserFeedLatest({ isSidebarVisible = true, colorPalette })
{
    const [feed, setFeed] = useState([]);
    const { hexToShadow } = useSessionData();

    useEffect(() => {
        getFeed();
    }, []);

    function getFeed()
    {
        axios.get('/api/user/feedLatest').then(function (response) {
            const feedArray = Object.keys(response.data).map((key) => ({
                id: key,
                ...response.data[key],
            }));
            setFeed(feedArray);
        });
    }

    return (
        <Grid container spacing={2}>
            {feed.map((item) => (
                <Grid
                    item
                    xs={12}
                    sm={6}
                    lg={isSidebarVisible ? 6 : 4}
                    key={item.id}
                >
                    <ShowFeedTestResults item={item} colorPalette={colorPalette} hexToShadow={hexToShadow} />
                </Grid>
            ))}
        </Grid>
    );
}
