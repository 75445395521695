import axios from "axios"
import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";

function renderHTMLContent(htmlString) {
    return { __html: stripHtmlTags(htmlString) };
}

const stripHtmlTags = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || '';
};

const columns = [
    { field: 'user_from_name', headerName: 'Küldő', flex: 1 },
    { field: 'user_to_name', headerName: 'Címzett', flex: 1 },
    { field: 'content',
        headerName: 'Üzenet',
        flex: 3,
        renderCell: (params) => (
            <div dangerouslySetInnerHTML={renderHTMLContent(params.row.content)} />
        ),
    },
    { field: 'date', headerName: 'Dátum', flex: 1 },
    {
        field: 'actions',
        headerName: '',
        flex: 1,
        renderCell: (params) => (
            <>
                <Button component={Link} to={`/view/message/${params.row.id}`} fullWidth variant={"outlined"} color={"info"}>Megnyitás</Button>
            </>
        ),
    },
    ];

export default function UserAllMessages()
{
    const [feed, setFeed] = useState([]);

    useEffect(() => {
        getFeed();
    }, []);

    function getFeed()
    {
        axios.get('/api/get/allMessages').then(function (response) {
            setFeed(response.data);
        });
    }

    return (
    <div style={{ height: '100%', width: '100%' }}>
        <DataGrid
            rows={feed}
            columns={columns}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            fullWidth
        />
    </div>
    )
}
