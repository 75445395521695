import * as React from 'react';
import axios from "axios";
import { useEffect } from "react";
import TitleBox from "../interface/TitleBox";
import ControlledCarousel from "../../util/ControlledCarousel";
import TestResultsLatest from "./TestResultsLatest";
import ViewMessagesLatest from "./ViewMessagesLatest";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Divider from "@mui/material/Divider";

export default function HomeScreen({ colorPalette, isSidebarVisible, setTextToSpeech }) {
    const [carouselData, setCarouselData] = React.useState([]);

    useEffect(() => {
        getCarouselData();
    }, []);

    function getCarouselData() {
        axios.get('/api/get/carousel').then(function (response) {
            setCarouselData(response.data);
        });
    }

    return (
        <>
            <ControlledCarousel carouselData={carouselData}/>
            <Grid container>
                <Grid item flex={11}><TitleBox title="Teszt eredmények" colorPalette={colorPalette} /></Grid>
                <Grid item flex={1} align={"right"}>
                    <Button component={Link} to={"/feed"} variant={"outlined"} sx={{ m: 2, justifyContent: 'space-between'}} endIcon={<KeyboardArrowRightIcon />}>
                        Összes
                    </Button>
                    <Divider/>
                </Grid>
            </Grid>
            <TestResultsLatest isSidebarVisible={isSidebarVisible} colorPalette={colorPalette}/>
            <Grid container sx={{ mt: 5 }}>
                <Grid item flex={11}><TitleBox title="Friss üzenetek" colorPalette={colorPalette} /></Grid>
                <Grid item flex={1} align={"right"}>
                    <Button component={Link} to={"/user/message/fresh"} variant={"outlined"} sx={{ m: 2, justifyContent: 'space-between'}} endIcon={<KeyboardArrowRightIcon />}>
                        Összes
                    </Button>
                    <Divider/>
                </Grid>
            </Grid>
            <ViewMessagesLatest inboxType="messagesLatest" colorPalette={colorPalette} isSidebarVisible={isSidebarVisible} setTextToSpeech={setTextToSpeech}/>
        </>
    );
}
