import {Box} from "@mui/system";
import Divider from "@mui/material/Divider";
import React from "react";

export default function TitleBox({ title }) {

    return (
        <Box>
            <h3>{title}</h3>
            <Divider sx={{ mb: 2 }}/>
        </Box>
    );
}
