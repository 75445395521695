import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import Button from "@mui/material/Button";
import Question from "./Question";
import {LinearProgress} from "@mui/material";
import TitleBox from "../interface/TitleBox";
import {useSessionData} from "../../auth/SessionDataProvider";

const StartTest = ({ colorPalette }) => {
    const { id } = useParams();
    const [testData, setTestData] = useState([]);
    const [testStarted, setTestStarted] = useState(false);
    const [testQuestions, setTestQuestions] = useState([{}]);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [questionNum, setQuestionNum] = useState(0);
    const [startedTestId, setStartedTestId] = useState(0);
    const {hexToShadow} = useSessionData();
    const navigate = useNavigate();

    useEffect(() => {
        getTest();
    }, [id]);

    function getTest() {
        const intId = parseInt(id, 10);
        axios
            .get(`/api/get/test/${intId}`)
            .then(function (response) {
                setTestData(response.data);
            })
            .catch(function (error) {
                console.error('Error fetching data:', error);
            });
    }

    function startTest() {
        const formData = new FormData();
        const questionCount = parseInt(testData.question_num, 10);
        formData.append('test_id', id);
        formData.append('question_count', questionCount)
        axios
            .post(`/api/start/test`, formData)
            .then(function (response) {
                if (response.data.result_id !== 0) {
                    setStartedTestId(response.data.result_id);
                    setTestQuestions(response.data.questions);
                    setQuestionNum(response.data.questions.length)
                    setCurrentQuestion(0);
                    setTestStarted(true);
                }
            })
            .catch(function (error) {
                console.error('Error fetching data:', error);
            });
    }

    if (!testStarted) {
        return (
            <Box className="chat-bubble"
                 sx={{
                     boxShadow: hexToShadow(colorPalette.shadow),
                     ml: 2, mr: 2
                 }}
            >
                <Box
                    className="chat-bubble-content"
                    bgcolor={colorPalette.paper}
                    sx={{ color: colorPalette.text, padding: '20px' }}
                >
                    <TitleBox title={testData.title} />
                    <div dangerouslySetInnerHTML={{ __html: testData.content }} className={"body1"}></div>
                    Kérdések száma: {testData.question_num}<br/>
                    Elvárt százalék: {testData.success_percent}%<br/>
                </Box>
                <Box className="chat-bubble-meta" bgcolor={colorPalette.dark} align={"center"}>
                <Button
                    fullWidth
                    variant="contained"
                    color="success"
                    onClick={startTest}
                    sx={{ mt: 2, mb: 2, width: '90%' }}
                >
                    Teszt elkezdése
                </Button>
                </Box>
            </Box>
        );
    }

    if (currentQuestion < questionNum) {
        const currentQuestionData = testQuestions[currentQuestion];
        return (
            <>
                <Box className="chat-bubble" sx={{ boxShadow: hexToShadow(colorPalette.shadow), ml: 2, mr: 2 }}>
                    <Box className="chat-bubble-content" bgcolor={colorPalette.paper} sx={{ color: colorPalette.text, padding: '20px' }}>
                <LinearProgress variant="determinate" value={currentQuestion * 100 / questionNum} sx={{ height: '10px' }} />
                <Question questionData={currentQuestionData} currentQuestion={currentQuestion} setCurrentQuestion={setCurrentQuestion} questionCount={questionNum} colorPalette={colorPalette} />
                    </Box>
                </Box>
            </>
        );
    }

    if (currentQuestion === questionNum) {
        navigate(`/view/test/${startedTestId}`);
        return null;
    }
};

export default StartTest;
