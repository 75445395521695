import React, { useEffect, useState } from 'react';
import Button from "@mui/material/Button";
import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/system";
import {useSessionData} from "../../auth/SessionDataProvider";

const useStyles = makeStyles(() => ({
    fadeContainer: {
        opacity: 0,
        transition: 'opacity 0.5s ease-in-out',
    },
    fadeContainerActive: {
        opacity: 1,
    },
}));

const QuestionWithAnswers = ({ questionData, colorPalette }) => {
    const { question, answers } = questionData;
    const classes = useStyles();
    const [isActive, setIsActive] = useState(false);
    const { hexToShadow } = useSessionData();

    useEffect(() => {
        const delay = setTimeout(() => {
            setIsActive(true);
        }, 100);

        return () => clearTimeout(delay);
    }, []);

    return (
        <>
            <Box className={`${classes.fadeContainer} ${isActive && classes.fadeContainerActive}`} sx={{ boxShadow: hexToShadow(colorPalette.shadow), ml: 2, mr: 2, mt: 2, mb: 2}}>
                <Box className="chat-bubble-meta" bgcolor={colorPalette.dark} color={colorPalette.text_on_dark}>
                    <div dangerouslySetInnerHTML={{ __html: question }} className={"h2"}></div>
                </Box>
                    <Box className="chat-bubble-content" bgcolor={colorPalette.paper} sx={{ color: colorPalette.text, padding: '20px',  height: '100%' }}>
                    {answers.map((answer, index) => {
                        let color = answer.good === 1 ? 'success' : answer.selected === 1 ? 'error' : 'primary';
                        let variant = answer.good === 1 ? 'contained' : answer.selected === 1 ? 'contained' : 'outlined';

                        return (
                            <React.Fragment key={index}>
                                <Button color={color} variant={variant} fullWidth={true} sx={{ margin: '5px auto'}}>
                                    <div dangerouslySetInnerHTML={{ __html: answer.answer }}></div>
                                </Button><br />
                            </React.Fragment>
                        );
                    })}
                </Box>
            </Box>
        </>
    );
};

export default QuestionWithAnswers;
