import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SendIcon from '@mui/icons-material/Send';
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Divider from "@mui/material/Divider";
import {CircularProgress} from "@mui/material";
import CourseStructureElement from "./CourseStructureElement";
import TitleBox from "../interface/TitleBox";
import CourseMenuBar from "./menubar/CourseMenuBar";

export default function CourseStructureView({ colorPalette, hexToShadow }) {
    const { parentId } = useParams();
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [course, setCourse] = useState([]);

    const getChilds = () => {
        setIsLoading(true);
        axios
            .get(`/api/get/courseItems/${parentId}`)
            .then(function (response) {
                setItems(response.data);
                setIsLoading(false);
            })
            .catch(function (error) {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
    };

    function getCourse() {
        axios.get(`/api/get/course/${parentId}`).then(function (response) {
            setCourse(response.data);
        });
    }

    useEffect(() => {
        getCourse();
        getChilds();
    }, [parentId]);

    if (isLoading) {
        return (
            <CircularProgress />
        );
    }

    return (
        <>
            <Grid>
                <CourseMenuBar parentId={parentId} colorPalette={colorPalette} hexToShadow={hexToShadow} />
            </Grid>
            <Grid>
                <TitleBox title={course.title} />
            </Grid>
            <Grid container>
                <Grid item xs align={"left"} sx={{ mb: 2, mt: 2, mr: 2 }}>
                    {items.length !== 0 && items.map((item, index) => (
                        <CourseStructureElement key={index} item={item} />
                    ))}
                </Grid>
            </Grid>
        </>
    );
}
