import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import {Link, useNavigate} from "react-router-dom";
import EditField from "../../interface/EditField";
import SendTopicComment from "./SendTopicComment";
import UserAvatar from "../../../user/UserAvatar";

const TopicCommentBubble = ({ comment, colorPalette, hexToShadow, sessionData }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [topicContent, setTopicContent] = useState('');
    const [isOwner, setIsOwner] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setTopicContent(comment.content);
        setIsOwner(comment.user_id === sessionData.user_id);
    }, [comment]);

    return (
        <Box
            className={`chat-bubble`}
            sx={{
                boxShadow: hexToShadow(colorPalette.shadow),
                ml: 2, mr: 2
            }}
        >
            <Box
                className="chat-bubble-content"
                bgcolor={isOwner ? colorPalette.paper : colorPalette.secondary}
            >
                {isEditing === true ? (
                    <EditField
                        fieldType={'comment'}
                        fieldId={comment.id}
                        content={topicContent}
                        setContent={setTopicContent}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                    />
                ) : (
                    <>
                        <div dangerouslySetInnerHTML={{__html: topicContent}} style={{ color: isOwner ? colorPalette.text : colorPalette.text_on_secondary, margin: 20 }}></div>
                    </>
                )}
            </Box>
            <Box className="chat-bubble-meta" bgcolor={colorPalette.dark}>
                <Grid container>
                    <Grid item sx={{ flex: 1}} align={"left"}>
                        <Box className="article_box_author_link" color={colorPalette.text_on_dark}>
                            <UserAvatar user={sessionData.session_users[comment.user_id]} />
                        </Box>
                    </Grid>
                    <Grid item sx={{ flex: 1}} align={"center"}>
                        <Box className="article_box_time" color={colorPalette.text_on_dark}>{comment.updated_at}</Box>
                    </Grid>
                    <Grid item sx={{ flex: 1}} align={"right"}>
                        {isOwner && (
                            <Button
                                sx={{ ml: 2, mr: 2 }}
                                variant="outlined"
                                color={isEditing === true ? 'error' : 'info'}
                                onClick={() => setIsEditing(!isEditing)}
                                endIcon={<EditIcon sx={{ mr: 2 }}/>}
                            >
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );

};

export default TopicCommentBubble;
