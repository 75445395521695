import React, { useState } from 'react';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import axios from "axios";
import ShowSnack from "../../util/ShowSnack";
import Divider from "@mui/material/Divider";
import {useNavigate} from "react-router-dom";
import UserSearchField from "../../user/UserSearchField";
import {useSessionData} from "../../auth/SessionDataProvider";

function AddMemberToGroup({ groupId, getMembers })
{
    const [selectedUser, setSelectedUser] = useState(null);
    const [snackOpen, setSnackOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const { sessionData } = useSessionData();

    const handleSubmit = async(e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('group_id', groupId);
        formData.append('user_id', selectedUser.id);
        formData.append('rights', 1);
        axios.post('/api/group/addMember', formData)
            .then(function (response) {
                if (response.data === 1) {
                    setSnackOpen(true);
                    setMessage('Hozzáadva!');
                    setSeverity('success');
                    getMembers();
                } else {
                    setSnackOpen(true);
                    setMessage('Sikertelen művelet!');
                    setSeverity('error');
                }
            });
    };

    return (
    <>
        <Divider />
        <Container>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <UserSearchField users={sessionData.friends} selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
                <Button
                    color={"success"}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 1, mb: 2 }}
                >
                    Hozzáadás
                </Button>
            </Box>
        </Container>
        <ShowSnack snackOpen={snackOpen} setSnackOpen={setSnackOpen} message={message} severity={severity} />
    </>
    );
}

export default AddMemberToGroup;
