import axios from "axios"
import React, { useEffect, useState } from "react";
import {CircularProgress} from "@mui/material";
import {Box} from "@mui/system";
import MessageBubble from "../../messages/MessageBubble";
import TopicCommentBubble from "./TopicCommentBubble";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

export default function ViewTopicComments({ topicId, sessionData, setTextToSpeech, colorPalette, hexToShadow })
{
    const [comments, setComments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        getFeed();
    }, [topicId]);

    function getFeed()
    {
        axios.get(`/api/get/topicComments/${topicId}`).then(function (response) {
            setComments(response.data);
            setIsLoading(false);
        });
    }

    if (isLoading === true) {
        return (
            <CircularProgress />
        );
    }

    return (
        <>
            <Grid container>
                {comments.map((comment, index) => (
                    <Grid item xs={12} key={index}>
                        <TopicCommentBubble comment={comment} sessionData={sessionData} setTextToSpeech={setTextToSpeech} colorPalette={colorPalette} hexToShadow={hexToShadow} />
                    </Grid>
                ))}
            </Grid>
        </>
    )
}
