import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import axios from "axios";
import { Chip, CircularProgress, Paper } from "@mui/material";
import Divider from "@mui/material/Divider";
import PieChartWithPaddingAngle from "../../util/PieChartWithPaddingAngle";
import Grid from "@mui/material/Grid";
import {Box} from "@mui/system";

const ShowTestResultsInFeed = ({ id }) => {
    const [testData, setTestData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [pieChartData, setPieChartData] = useState([]);

    useEffect(() => {
        getTestEvaluation();
    }, []);

    function getTestEvaluation() {
        axios
            .get(`/api/get/completedTest/${id}`)
            .then(function (response) {
                const responseData = response.data;
                const questionIds = responseData.questions.split(',').map(Number);
                const answerTimes = responseData.answer_times.split(',').map(Number);
                const goodAnswers = responseData.good_answers.split(',').map(Number);

                const newPieChartData = questionIds.map((questionId, index) => ({
                    label: `${index + 1}. Kérdés`,
                    value: answerTimes[index],
                    color: goodAnswers[index] === 1 ? '#4caf50' : '#ff3366'
                }));

                setTestData(responseData);
                setPieChartData(newPieChartData);
                setLoading(false);
            })
            .catch(function (error) {
                console.error('Error fetching data:', error);
            });
    }

    if (loading) {
        return (
            <CircularProgress />
        );
    }

    return (
        <>
            <Box sx={{ mb: 2 }}>
                <Typography variant="h4"
                            color={testData.success === 1 ? 'success.main' : 'error'}
                            sx={{
                                textShadow: '2px 2px 2px #1a1a1a',
                                opacity: 0.8,
                            }}
                >
                    {testData.success === 1 ? 'Sikeres' : 'Sikertelen'}!
                </Typography>
            </Box>
            <Grid container>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }} >
                    <div
                        style={{
                            width: '50%',
                            height: '0',
                            paddingBottom: '50%',
                            position: 'relative',
                            borderRadius: '50%',
                            border: '5px solid #1a1a1a',
                            textAlign: 'center',
                            overflow: 'hidden',
                        }}
                        className="round-container"
                    >
                    <Chip label={
                        <Typography
                            className="test-result-bigtext"
                            sx={{ fontSize: '1.3rem', fontWeight: 'bold'}}
                        >
                            {testData.result_percent}%
                        </Typography>
                    }
                          color={testData.success ? "success" : "error"}
                          className="test-result-big"
                          size={"large"}
                          sx={{
                              position: 'absolute',
                              top: '0',
                              left: '0',
                              width: '100%',
                              height: '100%',
                              opacity: 0.8,
                          }}
                    >
                    </Chip>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <PieChartWithPaddingAngle data={pieChartData} dimensions={100}/>
                </Grid>
            </Grid>
        </>
    );
};

export default ShowTestResultsInFeed;
