import React from 'react';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import DeleteButton from "../../../util/DeleteButton";
import axios from "axios";
import {useSessionData} from "../../../auth/SessionDataProvider";
import Button from "@mui/material/Button";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UserAvatar from "../../../user/UserAvatar";

const MemberCard = ({ member, group, colorPalette, getMembers }) => {
    const { isOwner, hexToShadow, sessionData, toggleSelectedUser, isSelectedUser } = useSessionData();
    const selected = isSelectedUser(member.user_id);
    const selectedMember = sessionData.session_users[member.user_id] ?? { name: 'Törölt felhasználó' };

    function removeMember() {
        const formData = new FormData();
        formData.append("user_group_id", member.id);
        axios.post('/api/group/removeMember', formData)
            .then(function (response) {
                if (response.data === 1) {
                    getMembers();
                }
            });
    }

    return (
        <Box
            className={`chat-bubble`}
            sx={{ boxShadow: hexToShadow(colorPalette.shadow), ml: 2, mr: 2 }}
        >
            <Box
                className="chat-bubble-content"
                bgcolor={colorPalette.paper}
            >
                    <Grid container sx={{ margin: 2 }}>
                        <Grid item flex={1}>
                            <UserAvatar user={selectedMember} />
                        </Grid>
                        <Grid item flex={5} sx={{ color: colorPalette.text }}>
                            {selectedMember.name}
                        </Grid>
                    </Grid>
            </Box>
            <Box className="chat-bubble-meta" bgcolor={colorPalette.dark}>
                <Grid container>
                    <Grid item sx={{ flex: 1}} align={"right"}>
                        {isOwner(group) && (
                            <DeleteButton action={removeMember} />
                        )}
                        {isOwner(group) && (
                            <Button
                                type="submit"
                                variant={selected ? "contained" : "outlined"}
                                color="success"
                                onClick={() => toggleSelectedUser(member.user_id)}
                                endIcon={selected ? <CheckCircleIcon/> : <RadioButtonUncheckedIcon/>}
                            >
                                {selected ? "Kiválasztva" : "Kiválasztás"}
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );

};

export default MemberCard;
