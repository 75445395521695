import axios from "axios"
import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";

const columns = [
    { field: 'id', headerName: 'ID', width: 70},
    { field: 'title', headerName: 'Title', flex: 2 },
    {
        field: 'actions',
        headerName: '',
        flex: 1,
        renderCell: (params) => (
            <>
                <Button component={Link} to={`/admin/list/subjects/${params.row.id}`} style={{marginRight: "10px"}}>Megnyitás</Button>
                <Button onClick={() => convertCourse(params.row.id)}>Konvertálás</Button>
            </>
        ),
    },
];

function convertCourse(courseId) {
    axios
        .get(`/api/course/convertCourse/${courseId}`)
        .then(function (response) {
            console.log(response.data);
        })
        .catch(function (error) {
            console.error('Error fetching data:', error);
        });
}

export default function CourseList()
{
    const [feed, setFeed] = useState([]);

    useEffect(() => {
        getFeed();
    }, []);

    function getFeed() {
        axios
            .get('/api/list/courses')
            .then(function (response) {
                setFeed(response.data);
                console.log(response.data);
            })
            .catch(function (error) {
                console.error('Error fetching data:', error);
            });
    }

    return (
    <div style={{ height: '100%', width: '100%' }}>
        <DataGrid
            rows={feed}
            columns={columns}
            pageSizeOptions={[5, 10]}
            fullWidth
        />
    </div>
    )
}
