import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ConfirmationDialog from "./ConfirmationDialog";

function DeleteButton({ action, confirm = false }) {
    const [isDeleteClicked, setIsDeleteClicked] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleClick = () => {
        if (!isDeleteClicked) {
            setIsDeleteClicked(true);
        } else {
            if (confirm) {
                setIsDialogOpen(true);
            } else if (action) {
                action();
            }
            setIsDeleteClicked(false);
        }
    };

    return (
        <>
            <Tooltip title="Eltávolítás" arrow>
                <Button
                    sx={{ ml: 2, mr: 2 }}
                    color={"error"}
                    variant={isDeleteClicked ? 'contained' : 'outlined'}
                    onClick={handleClick}
                    endIcon={<DeleteForeverOutlinedIcon sx={{ mr: 2 }} />}
                >
                    {isDeleteClicked ? 'Törlés' : ''}
                </Button>
            </Tooltip>
            <ConfirmationDialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                onConfirm={() => {
                    if (action) {
                        action();
                    }
                    setIsDialogOpen(false);
                }}
            />
        </>
    );
}

export default DeleteButton;
