import axios from "axios"
import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';

const columns = [
    { field: 'title', headerName: 'Cím', flex: 1 },
    { field: 'content', headerName: 'Szöveg', flex: 3 },
    { field: 'date', headerName: 'Dátum', flex: 1 },
    ];

export default function UserFeed()
{
    const [feed, setFeed] = useState([]);

    useEffect(() => {
        getFeed();
    }, []);

    function getFeed()
    {
        axios.get('/api/user/feed').then(function (response) {
            console.log(response.data)
            setFeed(response.data);
        });
    }

    return (
    <div style={{ height: '100%', width: '100%' }}>
        <DataGrid
            rows={feed}
            columns={columns}
            pageSizeOptions={[5, 10]}
            fullWidth
        />
    </div>
    )
}
