import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import React from "react";
import {Tooltip} from "@mui/material";

export default function StringAvatar({ userName, bgColor = 'default' })
{
    function stringToColor(string) {
        let color = bgColor;
        if (bgColor === 'default') {
            let hash = 0;
            let i;
            for (i = 0; i < string.length; i += 1) {
                hash = string.charCodeAt(i) + ((hash << 5) - hash);
            }
            color = '#';
            for (i = 0; i < 3; i += 1) {
                const value = (hash >> (i * 8)) & 0xff;
                color += `00${value.toString(16)}`.slice(-2);
            }
        }


        return color;
    }

    function stringAvatar(name) {
        if (name) {
            if (name.includes(' ')) {
                return {
                    sx: {
                        bgcolor: stringToColor(name),
                    },
                    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
                };
            } else if (name.length > 1) {
                return {
                    sx: {
                        bgcolor: stringToColor(name),
                    },
                    children: `${name[0]}`,
                };
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    return (
        <Tooltip title={userName} arrow>
            <Avatar {...stringAvatar(userName)} />
        </Tooltip>
    );
}
