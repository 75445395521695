import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Typography from "@mui/material/Typography";
import ShowTestResultVisualFeedback from "./ShowTestResultsInFeed";
import {useNavigate} from "react-router-dom";
const ShowFeedTestResults = ({ item, colorPalette, hexToShadow }) => {
    const navigate = useNavigate();

    return (
        <Grid
            container
            alignItems={"column"}
            className={`sidebar-profile-container`}
            sx={{ boxShadow: hexToShadow(colorPalette.paper), height: "100%", backgroundColor: colorPalette.paper }}
        >
            <Box className="article_box_header_link" sx={{ backgroundColor: colorPalette[item.color] ?? colorPalette.paper }} >
                <Typography variant="h6">
                    <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                    {item.parent_id && (
                        <ShowTestResultVisualFeedback id={item.parent_id} />
                    )}
                </Typography>
            </Box>
                <Grid container bgcolor={colorPalette.dark}>
                    <Grid item align={"left"} flex={3}>
                        <Typography variant="h6" color={colorPalette.text_on_dark} sx={{ ml: 2, mt: 2, mb: 2 }}>
                        {item.title}
                        </Typography>
                    </Grid>
                    <Grid item align={"right"} flex={1}>
                        <Button
                            variant="outlined"
                            color={"info"}
                            sx={{ mt: 2, mb: 2, mr: 2 }}
                            onClick={() => navigate(`/view/test/${item.parent_id}`)}
                        >
                            <KeyboardArrowRightIcon />
                        </Button>
                    </Grid>
                </Grid>
        </Grid>
    );

};

export default ShowFeedTestResults;
