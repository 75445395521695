import React, {forwardRef, useEffect, useState} from 'react';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import {IconButton, Tooltip} from "@mui/material";

function calculateRelativeLuminance(hexColor) {
    const removeHash = (hexColor) => hexColor.replace(/^#/, '');
    const parseHex = (hexColor) => parseInt(removeHash(hexColor), 16);
    const color = {
        r: parseHex(hexColor.slice(1, 3)) / 255,
        g: parseHex(hexColor.slice(3, 5)) / 255,
        b: parseHex(hexColor.slice(5, 7)) / 255,
    };

    for (const channel in color) {
        if (color[channel] <= 0.03928) {
            color[channel] /= 12.92;
        } else {
            color[channel] = Math.pow((color[channel] + 0.055) / 1.055, 2.4);
        }
    }

    return 0.2126 * color.r + 0.7152 * color.g + 0.0722 * color.b;
}

const NightModeSwitch = forwardRef(({ checked, toggleDarkMode, colorPalette }, ref) => {
    const [iconColor, setIconColor] = useState('#ffffff');

    useEffect(() => {
        const updateIconColor = () => {
            const luminance = calculateRelativeLuminance(colorPalette.appbar);
            setIconColor(luminance > 0.5 ? '#000000' : '#ffffff');
        };

        updateIconColor();
    }, [colorPalette]);

    return (
        <Tooltip title="Világos / Sötét" arrow sx={{ zIndex: 750 }}>
            <IconButton onClick={toggleDarkMode} color="inherit" ref={ref}>
                {checked === false ? <DarkModeIcon style={{ color: iconColor }} /> : <LightModeIcon style={{ color: iconColor }} />}
            </IconButton>
        </Tooltip>
    );
});

export default NightModeSwitch;
