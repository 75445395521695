import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import axios from "axios";
import ShowBubbleContent from "../../interface/ContentBox/ShowBubbleContent";
import LessonMenuBar from "./LessonMenuBar";

export default function ViewLesson({ sessionData, setTextToSpeech, colorPalette, hexToShadow })
{
    const { id } = useParams();
    const [lesson, setLesson] = useState([{}]);

    useEffect(() => {
        getLesson();
    }, [id]);

    function getLesson() {
        axios
            .get(`/api/get/lesson/${id}`)
            .then(function (response) {
                setLesson(response.data);
            })
            .catch(function (error) {
                console.error('Error fetching data:', error);
            });
    }
    return (
        <>
            <LessonMenuBar parent={lesson} colorPalette={colorPalette} />
            {lesson.image && (
                <img src={`/${lesson.image}`} alt="Lesson header" width={"100%"} />
            )}
            <ShowBubbleContent content={lesson.content} title={lesson.title} testId={lesson.test_id} colorPalette={colorPalette} setTextToSpeech={setTextToSpeech} hexToShadow={hexToShadow}/>
        </>
    );
}
