import React, { useEffect, useState } from 'react';
import {
    Paper,
    Typography,
    Button,
    Box,
    MobileStepper,
    IconButton,
    Slide,
} from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import {useNavigate} from "react-router-dom";

const ControlledCarousel = ({ carouselData }) => {
    const [activeStep, setActiveStep] = useState(0);
    const navigate = useNavigate();
    const maxSteps = carouselData.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps);
    };

    useEffect(() => {
        const autoPlayTimer = setInterval(() => {
            setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
        }, 5000);

        return () => {
            clearInterval(autoPlayTimer);
        };
    }, [maxSteps]);

    return (
        <Box style={{ overflow: 'hidden' }}>
            <Box
                style={{
                    display: 'flex',
                    transform: `translateX(-${activeStep * 100}%)`,
                    transition: 'transform 0.5s ease-in-out',
                }}
            >
                {carouselData.map((item, index) => (
                    <Paper
                        key={index}
                        elevation={0}
                        style={{
                            flex: '0 0 100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            backgroundImage: `url(${item.image})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '300px',
                            borderRadius: '15px',
                            cursor: 'pointer',
                        }}
                        onClick={() => navigate(item.link)}
                    >
                        <Typography variant="h4" align={"center"} sx={{ mt: 7, textShadow: '1px 1px 0 #FFF, -1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF' }}>
                            {item.content}
                        </Typography>
                    </Paper>
                ))}
            </Box>
            <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <IconButton size="small" onClick={handleNext}>
                        <KeyboardArrowRight />
                    </IconButton>
                }
                backButton={
                    <IconButton size="small" onClick={handleBack}>
                        <KeyboardArrowLeft />
                    </IconButton>
                }
            />
        </Box>
    );
};

export default ControlledCarousel;
