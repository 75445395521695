import axios from "axios"
import React, { useEffect, useState } from "react";
import {CircularProgress} from "@mui/material";
import Grid from "@mui/material/Grid";
import MessageBubble from "../messages/MessageBubble";

export default function ViewMessagesLatest({ inboxType = 'messagesLatest', page = 0, setTextToSpeech, colorPalette, hexToShadow, friends })
{
    const [feed, setFeed] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        getFeed();
    }, [inboxType, page]);

    function getFeed()
    {
        axios.get(`/api/get/${inboxType}/${page}`).then(function (response) {
            setFeed(response.data);
            setIsLoading(false);
        });
    }

    if (isLoading === true) {
        return (
            <CircularProgress />
        );
    }

    return (
        <>
            <Grid
                container
                spacing={2}
            >
                {feed.length !== 0 && feed.map((message, index) => (
                    <Grid item xs={6} key={index}>
                        <MessageBubble message={message} setTextToSpeech={setTextToSpeech} colorPalette={colorPalette} hexToShadow={hexToShadow} />
                    </Grid>
                ))}
            </Grid>
        </>
    )
}
