import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import SendNewMessage from "../SendNewMessage";
import {Link, useLocation} from "react-router-dom";

const MessagesMenuBar = ({ colorPalette, hexToShadow }) => {
    const [activeButton, setActiveButton] = useState(null);
    const location = useLocation();

    const toggleButton = (buttonName) => {
        setActiveButton(activeButton === buttonName ? null : buttonName);
    };

    return (
        <Box
            className="animated-resize-height"
            sx={{
                boxShadow: hexToShadow(colorPalette.sidebar),
                height: activeButton ? '420px' : '70px',
                marginBottom: '20px',
            }}
        >
            <Button
                color="info"
                variant={activeButton === 'newMessage' ? 'contained' : 'outlined'}
                sx={{ ml: 2, mr: 2, mb: 2, mt: 2 }}
                onClick={() => toggleButton('newMessage')}
                endIcon={<AddIcon sx={{ ml: 2 }} />}
            >
                Új üzenet
            </Button>
            <Button
                variant={location.pathname.includes('fresh') ? "contained" : ""}
                sx={{ mr: 2, mb: 2, mt: 2 }}
                component={Link}
                to="/user/message/fresh"
            >
                Friss
            </Button>
            <Button
                variant={location.pathname.includes('unread') ? "contained" : ""}
                sx={{ mr: 2, mb: 2, mt: 2 }}
                component={Link}
                to="/user/message/unread"
            >
                Olvasatlan
            </Button>
            <Button
                variant={location.pathname.includes('outgoing') ? "contained" : ""}
                sx={{ mr: 2, mb: 2, mt: 2 }}
                component={Link}
                to="/user/message/outgoing"
            >
                Kimenő
            </Button>
            <Button
                variant={location.pathname.includes('incoming') ? "contained" : ""}
                sx={{ mr: 2, mb: 2, mt: 2 }}
                component={Link}
                to="/user/message/incoming"
            >
                Bejövő
            </Button>
            <Button
                variant={location.pathname.includes('all') ? "contained" : ""}
                sx={{ mr: 2, mb: 2, mt: 2 }}
                component={Link}
                to="/user/message/all"
            >
                Összes
            </Button>
            <Box>
                {activeButton === 'newMessage' && <SendNewMessage />}
            </Box>
        </Box>
    );
};

export default MessagesMenuBar;
