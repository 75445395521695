import axios from "axios"
import React, { useEffect, useState } from "react";
import {CircularProgress} from "@mui/material";
import {Box} from "@mui/system";
import TitleBox from "../interface/TitleBox";
import GroupBubble from "./GroupBubble";
import GroupsMenuBar from "./menubar/GroupsMenuBar";
import Grid from "@mui/material/Grid";


export default function ViewGroups({ siteType = 'all', setTextToSpeech, colorPalette, hexToShadow, isSidebarVisible })
{
    const [groups, setGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const titleArray = {
        'all': 'Összes csoport',
        'messagesUnread': 'Olvasatlan üzenetek',
        'messagesOutgoing': 'Küldött üzenetek',
        'messagesIncoming': 'Beérkező üzenetek',
    }

    useEffect(() => {
        setIsLoading(true);
        getGroups();
    }, [siteType]);

    function getGroups()
    {
        axios.get(`/api/get/groups`)
            .then(function (response) {
                setGroups(response.data);
                setIsLoading(false);
            })
            .catch(function (error) {
                console.error("Error fetching data:", error);
            });
    }

    if (isLoading === true) {
        return (
            <CircularProgress />
        );
    }

    if (groups.length === 0) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                <h1 style={{ color: colorPalette.text }}>Nincs megjeleníthető csoport.</h1>
            </Box>
        );
    }

    return (
        <>
            <GroupsMenuBar colorPalette={colorPalette} hexToShadow={hexToShadow}  />
            <TitleBox title={titleArray[siteType]} />
            <Grid container>
                {groups.map((group, index) => (
                    <Grid item xs={12} sm={12} md={6} lg={isSidebarVisible ? 6 : 4} key={index}>
                        <GroupBubble group={group.group} setTextToSpeech={setTextToSpeech} colorPalette={colorPalette} hexToShadow={hexToShadow}/>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}
