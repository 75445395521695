import axios from "axios"
import React, { useEffect, useState } from "react";
import {CircularProgress} from "@mui/material";
import {useParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import GroupMenuBar from "../menubar/GroupMenuBar";
import MemberCard from "./MemberCard";
import {useSessionData} from "../../../auth/SessionDataProvider";


export default function ViewGroupMembers({ colorPalette })
{
    const [members, setMembers] = useState([]);
    const [group, setGroup] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { isSidebarVisible, hexToShadow, isOnPath } = useSessionData();
    const { id } = useParams();

    useEffect(() => {
        setIsLoading(true);
        getGroup();
        getMembers();
    }, [id]);

    function getGroup() {
        axios
            .get(`/api/get/group/${id}`)
            .then(function (response) {
                setGroup(response.data);
            })
    }

    function getMembers()
    {
        axios.get(`/api/group/members/${id}`).then(function (response) {
            setMembers(response.data);
            setIsLoading(false);
        });
    }

    if (isLoading === true) {
        return (
            <CircularProgress />
        );
    }

    return (
        <>
        {isOnPath('view/group') && <GroupMenuBar groupId={id} getMembers={getMembers} colorPalette={colorPalette} hexToShadow={hexToShadow} />}
            <Grid container>
                {members.map((member, index) => (
                    <Grid item xs={12} sm={12} md={6} lg={isSidebarVisible ? 6 : 4} key={index}>
                        <MemberCard member={member} group={group} getMembers={getMembers} colorPalette={colorPalette} />
                    </Grid>
                ))}
            </Grid>
        </>
    )
}
