import axios from "axios"
import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import MessageHistoryItem from "./MessageHistoryItem";
import ViewMessage from "./ViewMessage";
import ViewMessageWithInput from "./ViewMessageWithInput";
import {CircularProgress} from "@mui/material";
import MessageBubble from "./MessageBubble";
import {Box} from "@mui/system";
import Divider from "@mui/material/Divider";
import TitleBox from "../interface/TitleBox";
import MessagesMenuBar from "./menubar/MessagesMenuBar";


export default function ViewMessages({ inboxType = 'messagesLatest', page = 0, setTextToSpeech, colorPalette, hexToShadow, friends })
{
    const [feed, setFeed] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const titleArray = {
        'messagesLatest': 'Legújabb üzenetek',
        'messagesUnread': 'Olvasatlan üzenetek',
        'messagesOutgoing': 'Küldött üzenetek',
        'messagesIncoming': 'Beérkező üzenetek',
    }

    useEffect(() => {
        setIsLoading(true);
        getFeed();
    }, [inboxType, page]);

    function getFeed()
    {
        axios.get(`/api/get/${inboxType}/${page}`).then(function (response) {
            setFeed(response.data);
            setIsLoading(false);
        });
    }

    if (isLoading === true) {
        return (
            <CircularProgress />
        );
    }

    return (
        <>
            <MessagesMenuBar colorPalette={colorPalette} hexToShadow={hexToShadow} friends={friends} />
            <TitleBox title={titleArray[inboxType]} />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {feed.length !== 0 && feed.map((message, index) => (
                    <MessageBubble key={index} message={message} setTextToSpeech={setTextToSpeech} colorPalette={colorPalette} hexToShadow={hexToShadow} />
                ))}
            </Box>
        </>
    )
}
