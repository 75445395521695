import React, { useState, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useSessionData} from "../../auth/SessionDataProvider";
import ShowSnack from "../../util/ShowSnack";
import Typography from "@mui/material/Typography";
import SaveIcon from '@mui/icons-material/Save';
import NotesIcon from '@mui/icons-material/Notes';
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";

export default function UserNotes({ colorPalette })
{
    const editorRef = useRef(null);
    const { sessionData, setSessionData, hexToShadow } = useSessionData();
    const [snackOpen, setSnackOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [isEditing, setIsEditing] = useState(false);

    const handleSubmit = async(e) => {
        e.preventDefault();
        const freshNotes = editorRef.current.getContent();
        const formData = new FormData();
        formData.append('content', freshNotes);
        axios.post('/api/edit/notes', formData)
            .then(function (response) {
                if (response.data  === 1) {
                    setSnackOpen(true);
                    setMessage('Sikeres mentés!');
                    setSeverity('success');
                    setIsEditing(false);
                    setSessionData({
                        ...sessionData,
                        notes: freshNotes,
                    });
                } else {
                    setSnackOpen(true);
                    setMessage('Sikertelen művelet!');
                    setSeverity('error');
                }
            });
    };

    return (
        <>
            <Box
                className={`sidebar-profile-container`}
                bgcolor={"#FFFF99"}
                sx={{ boxShadow: hexToShadow("#FFFF99"), mb: 2 }}
            >
                <Grid container className="chat-bubble-meta" bgcolor={colorPalette.dark} >
                    <Grid item flex={1} >
                        <Typography variant={"h6"} color={colorPalette.text_on_dark}><NotesIcon sx={{ mr: 2 }}/> Jegyzet</Typography>
                    </Grid>
                    <Grid item flex={1} align={"right"}>
                        {isEditing === true ? (
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="success"
                                onClick={handleSubmit}
                                endIcon={<SaveIcon/>}
                            >
                                Mentés
                            </Button>
                            ) : (
                            <Button
                                variant="outlined"
                                color="info"
                                onClick={() => setIsEditing(true)}
                                endIcon={<EditIcon sx={{ mr: 2 }}/>}
                            >
                            </Button>
                        )}

                    </Grid>
                </Grid>
                {isEditing === true ? (
                <Editor
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                    onInit={(evt, editor) => editorRef.current = editor}
                    initialValue={sessionData.notes}
                    init={{
                        height: 300,
                        menubar: false,
                        toolbar: false,
                        statusbar: false,
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:18px }'
                    }}
                />
                ) : (
                    <Box sx={{ mr: 2, ml: 2, padding: '10px' }}>
                        <div dangerouslySetInnerHTML={{__html: sessionData.notes }} style={{ color: colorPalette.text, textAlign: 'justify' }} ></div>
                    </Box>
                )}
            </Box>
            <ShowSnack snackOpen={snackOpen} setSnackOpen={setSnackOpen} message={message} severity={severity} />
        </>
    );
}
