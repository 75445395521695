import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import { PieChart } from '@mui/x-charts/PieChart';

export default function AnimatedPieChart({ data, dimensions = 200 }) {
    const [animatedData, setAnimatedData] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [totalSeconds, setTotalSeconds] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const innerRadiusCalc = dimensions / 3;
    const outerRadiusCalc = dimensions / 2.2;

    useEffect(() => {
        const animationInterval = setInterval(() => {
            animateChart();
        }, 320);
        return () => clearInterval(animationInterval);
    }, [currentIndex]);

    const animateChart = () => {
        if (!data) {
            return;
        }
        if (currentIndex === data.length) {
            return;
        }

        const slicedData = data.slice(0, currentIndex + 1);
        let newTotalSeconds = 0;
        for (let i = 0; i < slicedData.length; i++) {
            if (slicedData[i].value === '0') {
                slicedData[i].value = 1;
            }
            newTotalSeconds += parseInt(slicedData[i].value, 10);
        }
        setTotalSeconds(newTotalSeconds);
        setAnimatedData(slicedData);
        setIsVisible(true);

        setCurrentIndex(currentIndex + 1);
    };

    return (
        <Stack direction="row">
            <PieChart
                series={[
                    {
                        // startAngle: -90,
                        // endAngle: 90,
                        paddingAngle: 5,
                        innerRadius: innerRadiusCalc,
                        outerRadius: outerRadiusCalc,
                        cornerRadius: 5,
                        data: animatedData,
                    },
                ]}
                margin={{ right: 5, left: 5, bottom: 0, top: 0 }}
                width={dimensions}
                height={dimensions}
                legend={{ hidden: true }}
                sx={{
                    opacity: isVisible ? 1 : 0,
                    transition: 'opacity 0.5s',
                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
                }}
            >
                <text
                    x="50%"
                    y="50%"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    fontSize="20"
                >
                    {totalSeconds} mp
                </text>
            </PieChart>
        </Stack>
    );
}
