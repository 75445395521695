import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import DeleteButton from "../../../util/DeleteButton";
import axios from "axios";
import {useSessionData} from "../../../auth/SessionDataProvider";

const EditOrderItem = ({ order, colorPalette, hexToShadow,getOrder }) => {
    const { isOwner } = useSessionData();

    function deleteOrder() {
        const formData = new FormData();
        formData.append("order_id", order.id);
        axios.post('/api/group/deleteOrder', formData)
            .then(function (response) {
                if (response.data === 1) {
                    getOrder();
                }
            });
    }

    return (
        <Box className={`chat-bubble`} sx={{
                boxShadow: hexToShadow(colorPalette.shadow),
                ml: 2, mr: 2
        }}>
            <Box className="chat-bubble-content" bgcolor={colorPalette.paper}>
                <div style={{ color: colorPalette.text, margin: 20 }}>{order.title}</div>
            </Box>
            <Box className="chat-bubble-meta" bgcolor={colorPalette.dark}>
                <Grid container>
                    <Grid item sx={{ flex: 1}} align={"right"}>
                        {isOwner(order) && (
                            <DeleteButton action={deleteOrder} />
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );

};

export default EditOrderItem;
