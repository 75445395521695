import {useSessionData} from "./SessionDataProvider";

function RequireAuth({ children })
{
    const { sessionData } = useSessionData();

    if (sessionData.logged === 1) {
        return children;
    }

    return null;
}

export default RequireAuth
