import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import {Link} from 'react-router-dom';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {Tooltip} from "@mui/material";
import {useSessionData} from "../../../auth/SessionDataProvider";

const TaskManagerMenuBar = ({ groupId, colorPalette }) => {
    const { sessionData, isOnPath, hexToShadow } = useSessionData();

    return (
        <Box
            className={'animated-resize-height'}
            sx={{
                boxShadow: hexToShadow(colorPalette.sidebar),
                marginBottom: '20px',
            }}
        >
            <Tooltip title={"Vissza a csoporthoz"} arrow>
                <Button
                    color={"info"}
                    variant={isOnPath("/view/group") ? "contained" : "outlined"}
                    component={Link}
                    to={`/view/group/${groupId}`}
                    sx={{ ml: 2, mr: 2, mb: 2, mt: 2 }}
                >
                    <RemoveRedEyeIcon/>
                </Button>
            </Tooltip>
            <Tooltip title={"Feladatok"} arrow>
                <Button
                    color={"info"}
                    variant={isOnPath(`/view/manager/${groupId}/progress`) ? "contained" : "outlined"}
                    component={Link}
                    disabled={sessionData.selected_users.length === 0}
                    to={`/view/manager/${groupId}/progress`}
                    sx={{ mr: 2, mb: 2, mt: 2 }}
                >
                    Feladatok
                </Button>
            </Tooltip>
        </Box>
    );
};

export default TaskManagerMenuBar;
