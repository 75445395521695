import {Alert, Snackbar} from "@mui/material";
import React from "react";

export default function ShowSnack({ snackOpen, setSnackOpen, message, severity })
{
    /*
    const [snackOpen, setSnackOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');

    setSnackOpen(true);
    setMessage('message');
    setSeverity('success');

    <ShowSnack snackOpen={snackOpen} setSnackOpen={setSnackOpen} message={message} severity={severity} />
     */

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackOpen(false);
    };

    return (
        <Snackbar
            open={snackOpen}
            autoHideDuration={10000}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
                }}
        >
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
        );
}
