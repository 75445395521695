import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import EditField from "../interface/EditField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import HearingIcon from "@mui/icons-material/Hearing";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import SendIcon from "@mui/icons-material/Send";
import {useNavigate} from "react-router-dom";
import {useSessionData} from "../../auth/SessionDataProvider";
import StringAvatar from "../../user/StringAvatar";
import UserAvatar from "../../user/UserAvatar";

const MessageBubble = ({ message, setTextToSpeech, colorPalette }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [messageContent, setMessageContent] = useState('');
    const { hexToShadow, sessionData } = useSessionData();
    const navigate = useNavigate();

    useEffect(() => {
        setMessageContent(message.content);
    }, []);

    return (
        <Box
            className="chat-bubble"
            sx={{
                marginLeft: message.is_owner ? "auto" : "initial",
                marginRight: message.is_owner ? "initial" : "auto",
                boxShadow: hexToShadow(message.is_owner ? colorPalette.paper : colorPalette.secondary)
            }}
        >
            <Box
                className="article_box_header_link"
                bgcolor={message.is_owner ? colorPalette.paper : colorPalette.secondary}
            >
                {isEditing === true ? (
                    <EditField
                        fieldType={'message'}
                        fieldId={message.id}
                        content={messageContent}
                        setContent={setMessageContent}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                    />
                ) : (
                    <>
                        <div dangerouslySetInnerHTML={{__html: messageContent}} style={{ color: message.is_owner ? colorPalette.text : colorPalette.text_on_secondary }}></div>
                    </>
                )}
            </Box>
            <Box className="article_box_meta" bgcolor={colorPalette.dark}>
                <Grid container>
                    <Grid item xs={2}>
                        <UserAvatar user={sessionData.session_users[message.user_from]} />
                    </Grid>
                    <Grid item xs={5}>
                        <Box className="article_box_author_link" color={colorPalette.text_on_dark}>{message.user_from_name}</Box>
                        <Box className="article_box_time" color={colorPalette.text_on_dark}>
                            <Grid container spacing={1}>
                                <Grid item>
                                    {message.date}
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Button color="info" onClick={() => (setTextToSpeech(message.content))}>
                            <VolumeUpIcon />
                        </Button>
                    </Grid>
                    <Grid item xs={2} align={"right"}>
                        {/*{message.is_owner === true && (*/}
                        {/*    <Button*/}
                        {/*        sx={{ ml: 2, mb: 2 }}*/}
                        {/*        variant="outlined"*/}
                        {/*        color={isEditing === true ? 'error' : 'info'}*/}
                        {/*        onClick={() => setIsEditing(!isEditing)}*/}
                        {/*    >*/}
                        {/*        <EditIcon/> Szerkesztés*/}
                        {/*    </Button>*/}
                        {/*)}*/}
                        <Button
                            variant="outlined"
                            color={"info"}
                            disabled={isEditing === true}
                            sx={{ ml: 2 }}
                            onClick={() => navigate(`/view/message/${message.id}`)}
                        >
                            <KeyboardArrowRightIcon/>
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );

};

export default MessageBubble;
