import React, {useEffect, useState} from 'react';
import TaskManagerMenuBar from "./TaskManagerMenuBar";
import {Route, Routes, useNavigate, useParams} from "react-router-dom";
import ProgressTree from "./ProgressTree";
import {useSessionData} from "../../../auth/SessionDataProvider";
import TitleBox from "../../interface/TitleBox";
import axios from "axios";
import Grid from "@mui/material/Grid";
import GroupBubble from "../GroupBubble";
import GroupBubbleTitle from "../GroupBubbleTitle";
import ViewGroupMembers from "../members/ViewGroupMembers";
export default function TaskManager({ colorPalette })
{
    const { isOnPath, hexToShadow, sessionData, setSessionData } = useSessionData();
    const { id } = useParams();
    const navigate = useNavigate();
    const [groups, setGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        if (id !== '0') {
            setSessionData((prevData) => ({
                ...prevData,
                selectedGroup: id,
            }));
            setIsLoading(false);
        }
        if (id === '0') {
            getGroups();
        }
    }, [id]);

    function getGroups()
    {
        axios.get(`/api/get/groups`)
            .then(function (response) {
                setGroups(response.data);
                setIsLoading(false);
            })
            .catch(function (error) {
                console.error("Error fetching data:", error);
            });
    }

    if (isLoading) return (<div>Loading...</div>);

    if (id !== '0') {
        return (
            <>
                <TaskManagerMenuBar groupId={id} colorPalette={colorPalette}/>
                {(sessionData.selected_users.length === 0 && isOnPath(`view/manager/${id}/select`) === false) && navigate(`/view/manager/${id}/select`)}
                {isOnPath(`view/manager/${id}/select`) && <ViewGroupMembers colorPalette={colorPalette}/>}
                {isOnPath(`view/manager/${id}/progress`) && <ProgressTree colorPalette={colorPalette} groupId={id}/>}
            </>
        );
    }

    return (
        <>
            <TitleBox title={"Válassz csoportot"} colorPalette={colorPalette}/>
            <Grid container>
                <Grid container>
                    {groups.map((group, index) => (
                        <Grid item xs={12} sm={12} md={6}key={index}>
                            <GroupBubbleTitle group={group.group} colorPalette={colorPalette} hexToShadow={hexToShadow}/>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </>
    );
}
