import axios from "axios"
import React, {useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import Button from "@mui/material/Button";
import SendIcon from '@mui/icons-material/Send';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {Editor} from "@tinymce/tinymce-react";
import {CircularProgress, Paper} from "@mui/material";
import ShowSnack from "../../util/ShowSnack";
import ViewMessage from "./ViewMessage";
import { CSSTransition } from 'react-transition-group';

export default function SendMessage({ userTo, userFrom, parentId = 0 })
{
    const editorRef = useRef(null);
    const [isActive, setIsActive] = useState(false);
    const [inputs, setInputs] = useState([]);
    const [snackOpen, setSnackOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [sentMessageId, setSentMessageId] = useState(0);

    const sendMessage = async(e) => {
        setIsLoading(true);
        const formData = new FormData();
        for (const [key, value] of Object.entries(inputs)) {
            formData.append(key, value);
        }
        formData.append('user_to', userTo);
        formData.append('user_from', userFrom);
        formData.append('parent_id', parentId);
        axios.post('/api/save/message', formData)
            .then(function (response) {
                if (response.data  !== 0) {
                    setSentMessageId(response.data);
                    setIsSent(true);
                    setSnackOpen(true);
                    setMessage('Üzenet elküldve!');
                    setSeverity('success');
                } else {
                    setSnackOpen(true);
                    setMessage('Sikertelen művelet!');
                    setSeverity('error');
                    setIsLoading(false);
                }
            });
    };

    if (isSent === true) {
        return (
            <CSSTransition
                in={isSent}
                timeout={2000}
                classNames="fade"
                unmountOnExit
            >
                <ViewMessage id={sentMessageId} />
            </CSSTransition>
        );
    }

    if (isActive === true) {
        return (
            <>
                <Paper elevation={3} sx={{ mb: 5 }}>
                    <Grid container>
                        <Grid item xs align={"right"} sx={{ mb: -5 }}>
                            <Button onClick={() => setIsActive(false)} className={"get-to-top"}><HighlightOffIcon/></Button>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Editor
                            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                            onInit={(evt, editor) => editorRef.current = editor}
                            onEditorChange={(newValue, editor) => {
                                setInputs(values => ({ ...values, content: newValue }));
                            }}
                            init={{
                                height: 210,
                                menubar: false,
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                    'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                                ],
                                toolbar: 'undo redo | blocks | ' +
                                    'bold italic forecolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat | help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                        />
                    </Grid>
                    <Grid container>
                        <Grid item xs align={"right"} sx={{ mb: 2, mt: 2, mr: 2 }}>
                            <Button
                                onClick={() => sendMessage()}
                                color={"success"}
                                variant={"outlined"}
                                disabled={isLoading}
                                endIcon={isLoading ? <CircularProgress size={20} sx={{ ml: 2 }}/> : <SendIcon sx={{ ml: 2 }} />}
                            >
                                Küldés
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
                <ShowSnack snackOpen={snackOpen} setSnackOpen={setSnackOpen} message={message} severity={severity} />
            </>
        );
    }

    return (
        <Grid container>
            <Grid item xs align={"right"}>
                <Button onClick={() => setIsActive(true)} sx={{ fontSize: '18px' }} endIcon={<SendIcon sx={{ ml: 2 }}/>}>Válasz</Button>
            </Grid>
        </Grid>
    );
}
