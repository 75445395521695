import React from 'react';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {useNavigate} from "react-router-dom";
import StringAvatar from "./StringAvatar";
import UserAvatar from "./UserAvatar";
const UserCardSmall = ({ userCard, colorPalette, hexToShadow }) => {
    const navigate = useNavigate();

    return (
        <Box
            className={`sidebar-profile-container`}
            bgcolor={colorPalette.paper}
            sx={{ boxShadow: hexToShadow(colorPalette.paper) }}
        >
            <Box className="article_box_header_link" bgcolor={colorPalette.paper}>
                <Grid container>
                    <Grid item sx={{ flex: 1}}>
                        <UserAvatar user={userCard} />
                    </Grid>
                    <Grid item sx={{ flex: 4}}>
                        {userCard.name}
                    </Grid>
                </Grid>
            </Box>
            <Box bgcolor={colorPalette.dark}>
                <Grid container>
                    <Grid item xs={12} align={"right"}>
                        <Button
                            key={userCard.id}
                            variant="outlined"
                            color={"success"}
                            sx={{ ml: 2, mt: 1, mr: 2, mb: 1 }}
                            onClick={() => navigate(`/user/profile/${userCard.id}`)}
                        >
                            Megnyit <KeyboardArrowRightIcon/>
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );

};

export default UserCardSmall;
