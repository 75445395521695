import axios from "axios"
import React, { useEffect, useState } from "react";
import {CircularProgress} from "@mui/material";
import {useParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import TitleBox from "../../interface/TitleBox";
import GroupBubble from "../GroupBubble";
import TopicBubble from "./TopicBubble";


export default function ViewTopics({ groupId, siteType = 'all', setTextToSpeech, colorPalette, hexToShadow, isSidebarVisible, sessionData })
{
    const [topics, setTopics] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { page } = useParams();

    const titleArray = {
        'all': 'Összes téma',
    }

    useEffect(() => {
        setIsLoading(true);
        getTopics();
    }, [siteType, page]);

    function getTopics()
    {
        axios.get(`/api/get/topics/${groupId}`).then(function (response) {
            setTopics(response.data);
            setIsLoading(false);
        });
    }

    if (isLoading === true) {
        return (
            <CircularProgress />
        );
    }

    return (
        <>
            <TitleBox title={titleArray[siteType]} />
            <Grid container>
                {topics.map((topic, index) => (
                    <Grid item xs={12} sm={12} md={6} lg={isSidebarVisible ? 12 : 6} key={index}>
                        <TopicBubble topic={topic} setTextToSpeech={setTextToSpeech} colorPalette={colorPalette} hexToShadow={hexToShadow} sessionData={sessionData}/>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}
