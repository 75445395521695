import React, {useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import TextField from "@mui/material/TextField";
import {Editor} from "@tinymce/tinymce-react";
import Button from "@mui/material/Button";
import ShowSnack from "../../../util/ShowSnack";
import TestQuestionList from "./TestQuestionList";
import Divider from "@mui/material/Divider";

export default function QuestionEditor()
{
    const editorRef = useRef(null);
    const { id } = useParams();
    const [testData, setTestData] = useState([]);
    const [testContent, setTestContent] = useState('');
    const [inputs, setInputs] = useState([]);
    const [snackOpen, setSnackOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');


    useEffect(() => {
        getTest();
    }, [id]);

    function getTest() {
        axios
            .get(`/api/get/questionAndAnswers/${id}`)
            .then(function (response) {
                console.log(response.data);
                setTestData(response.data);
                setTestContent(response.data.content);
                setInputs(values => ({ ...values, title: response.data.title }));
                setInputs(values => ({ ...values, content: response.data.content }));
                const initialInputValues = {};
                response.data.answers.forEach((answer) => {
                    initialInputValues[`answer_${answer.id}`] = answer.answer;
                });
                setInputs(values => ({ ...values, ...initialInputValues }));
            })
            .catch(function (error) {
                console.error('Error fetching data:', error);
            });
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        const formData = new FormData();
        for (const [key, value] of Object.entries(inputs)) {
            formData.append(key, value);
        }
        formData.append('question_id', id);
        axios.post('/api/edit/questionAndAnswers', formData)
            .then(function (response) {
                if (response.data  === 1) {
                    setSnackOpen(true);
                    setMessage('Sikeres mentés!');
                    setSeverity('success');
                } else {
                    setSnackOpen(true);
                    setMessage('Sikertelen művelet!');
                    setSeverity('error');
                }
            });
    };

    const deleteQuestion = async(e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('question_id', id);
        axios.post('/api/delete/question', formData)
            .then(function (response) {
                if (response.data  === 1) {
                    setSnackOpen(true);
                    setMessage('Sikeres törlés!');
                    setSeverity('info');
                    window.location.href = `/edit/test/${testData.test_id}`;
                } else {
                    setSnackOpen(true);
                    setMessage('Sikertelen művelet!');
                    setSeverity('error');
                }
            });
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }));
    }

    useEffect(() => {
        getTest();
    }, [id]);

    return (
        <>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Typography variant={"h6"} sx={{ mb: 2 }}>Kérdés</Typography>
                <Editor
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                    onInit={(evt, editor) => editorRef.current = editor}
                    initialValue={testContent}
                    onEditorChange={(newValue, editor) => {
                        setInputs(values => ({ ...values, content: newValue }));
                    }}
                    init={{
                        height: 250,
                        menubar: false,
                        plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                            'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                        ],
                        toolbar: 'undo redo | blocks | ' +
                            'bold italic forecolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                />
                <Typography variant={"h6"} sx={{ mt: 3, mb: 2 }}>Válaszok</Typography>
                <Box>
                    {testData.answers && testData.answers.map((answer, index) => (
                        <TextField
                            key={index}
                            label={index === 0 ? `Jó válasz` : `Rossz válasz`}
                            name={`answer_${answer.id}`}
                            value={inputs[`answer_${answer.id}`] || ''}
                            color={index === 0 ? 'success' : 'error'}
                            onChange={handleChange}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                    ))}
                </Box>
                <Box>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="success"
                        sx={{ mt: 5, mb: 3 }}
                    >
                        Mentés
                    </Button>
                    <Button
                        onClick={deleteQuestion}
                        fullWidth
                        variant="outlined"
                        color="error"
                        sx={{ mt: 10, mb: 3 }}
                    >
                        Kérdés törlése
                    </Button>
                </Box>
            </Box>
            <ShowSnack snackOpen={snackOpen} setSnackOpen={setSnackOpen} message={message} severity={severity} />
        </>
    );
}
