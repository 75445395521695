import axios from "axios"
import React, { useState } from "react";
import {useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import ViewMessage from "./ViewMessage";
import Button from "@mui/material/Button";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import MessageBubble from "./MessageBubble";

export default function MessageViewWithHistory({ setTextToSpeech, colorPalette })
{
    const [messages, setMessages] = useState([]);
    const [historyVisible, setHistoryVisible] = useState(false);
    const { id } = useParams();

    function getMessageHistory() {
        axios
            .get(`/api/get/messageHistory/${id}`)
            .then(function (response) {
                console.log(response.data);
                setMessages(response.data);
                setHistoryVisible(true);
            })
            .catch(function (error) {
                console.error('Error fetching data:', error);
            });
    }

    function setActive () {
        getMessageHistory();
    }

    if (historyVisible === true) {
        return (
            <Box>
                {messages.map((message, index) => (
                    index < messages.length - 1 ? (
                        <MessageBubble message={message} setTextToSpeech={setTextToSpeech} colorPalette={colorPalette} />
                    ) : null
                ))}
                <ViewMessage id={id} singleUnit={true} setTextToSpeech={setTextToSpeech} />
            </Box>
        );
    }

    return (
        <Box>
            <Button onClick={() => setActive()} fullWidth sx={{ fontSize: '20px' }}><ManageSearchIcon /> ... Előzmények</Button>
            <ViewMessage id={id} singleUnit={true} setTextToSpeech={setTextToSpeech} />
        </Box>
    );
}
