import axios from "axios"
import React, {useRef, useState} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SendIcon from '@mui/icons-material/Send';
import {Editor} from "@tinymce/tinymce-react";
import {CircularProgress, Paper} from "@mui/material";
import {useNavigate} from "react-router-dom";
import ShowSnack from "../../../util/ShowSnack";

export default function CreateTopic({ groupId })
{
    const editorRef = useRef(null);
    const [inputs, setInputs] = useState([]);
    const [snackOpen, setSnackOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [sentMessageId, setSentMessageId] = useState(0);
    const [selectedUser, setSelectedUser] = useState(null);
    const navigate = useNavigate();

    const sendMessage = async(e) => {
        setIsLoading(true);
        const formData = new FormData();
        for (const [key, value] of Object.entries(inputs)) {
            formData.append(key, value);
        }
        formData.append('group_id', groupId);
        axios.post('/api/create/topic', formData)
            .then(function (response) {
                console.log(response.data);
                if (response.data  !== 0) {
                    setSentMessageId(response.data);
                    setIsSent(true);
                    setSnackOpen(true);
                    setMessage('Téma létrehozva!');
                    setSeverity('success');
                    navigate(`/view/group/${groupId}`)
                } else {
                    setSnackOpen(true);
                    setMessage('Sikertelen művelet!');
                    setSeverity('error');
                }
                setIsLoading(false);
            });
    };

    return (
        <>
            <Box sx={{ mr: 2, ml: 2, mb: 2}} align={"center"}>
                <Grid>
                    <Editor
                        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                        onInit={(evt, editor) => editorRef.current = editor}
                        onEditorChange={(newValue, editor) => {
                            setInputs(values => ({ ...values, content: newValue }));
                        }}
                        init={{
                            height: 270,
                            menubar: false,
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                            ],
                            toolbar: 'undo redo | blocks | ' +
                                'bold italic forecolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                    />
                </Grid>
                <Grid container>
                    <Grid item xs align={"right"} sx={{ mb: 2, mt: 2, mr: 2 }}>
                        <Button
                            onClick={() => sendMessage()}
                            color={"success"}
                            variant={"outlined"}
                            disabled={isLoading}
                            endIcon={isLoading ? <CircularProgress size={20} sx={{ ml: 2 }}/> : <SendIcon sx={{ ml: 2 }} />}
                        >
                            Küldés
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <ShowSnack snackOpen={snackOpen} setSnackOpen={setSnackOpen} message={message} severity={severity} />
        </>
    );
}
