import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import GroupsMenuBar from "../menubar/GroupsMenuBar";
import TitleBox from "../../interface/TitleBox";
import CourseStructureEditor from "../../courses/CourseStructureEditor";
import { HTML5Backend } from "react-dnd-html5-backend";
import { CircularProgress } from "@mui/material";
import EditOrderItem from "./EditOrderItem";
import GroupMenuBar from "../menubar/GroupMenuBar";

const ItemType = "ORDER_ITEM";

const OrderItemDragAndDrop = ({ order, index, handleDragEnd, colorPalette, hexToShadow, sessionData, getOrder }) => {
    const [{ isDragging }, drag] = useDrag({
        type: ItemType,
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const draggedClass = isDragging ? "dragged-item" : "draggable-item";

    // Use the useDrop hook to handle the drop event
    const [, drop] = useDrop({
        accept: ItemType,
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                handleDragEnd(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    return (
        <div ref={(node) => drag(drop(node))} className={draggedClass}>
            <EditOrderItem
                order={order}
                colorPalette={colorPalette}
                hexToShadow={hexToShadow}
                sessionData={sessionData}
                dragHandleProps={drag}
                getOrder={getOrder}
            />
        </div>
    );
};

const EditOrder = ({ colorPalette, hexToShadow, isSidebarVisible, sessionData }) => {
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [reorderedOrders, setReorderedOrders] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        getOrder();
    }, [id]);

    function getOrder() {
        axios.get(`/api/group/order/${id}`).then(function (response) {
            console.log(response.data);
            setOrders(response.data);
            setReorderedOrders(response.data);
            setIsLoading(false);
        });
    }

    function saveNewOrder() {
        const formData = new FormData();
        const orderData = reorderedOrders.map((order, index) => ({ id: order.id, order_num: index }));
        formData.append("orders", JSON.stringify(orderData));

        axios.post('/api/group/saveOrder', formData)
            .then(function (response) {
                if (response.data === 1) {
                    // Handle success
                }
                return true;
            });

        return false;
    }

    const handleNewOrder = async () => {
        try {
            saveNewOrder();
        } catch (error) {
            console.log(error);
        }
    };

    const handleDragEnd = (sourceIndex, destinationIndex) => {
        const updatedOrders = [...reorderedOrders];
        const [reorderedItem] = updatedOrders.splice(sourceIndex, 1);
        updatedOrders.splice(destinationIndex, 0, reorderedItem);
        const reorderedItems = updatedOrders.map((order, index) => ({
            ...order,
            order_num: index,
        }));
        setReorderedOrders(reorderedItems);
        handleNewOrder();
    };

    if (isLoading) {
        return <CircularProgress size={20} sx={{ ml: 2 }} />;
    }

    return (
        <>
            <GroupMenuBar groupId={id} colorPalette={colorPalette} hexToShadow={hexToShadow}/>
            <DndProvider backend={HTML5Backend}>
                <Grid container>
                    <Grid item xs={6}>
                        <CourseStructureEditor
                            id={0}
                            groupId={id}
                            getOrder={getOrder}
                            colorPalette={colorPalette}
                            hexToShadow={hexToShadow}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TitleBox title={"Tanmenet"} />
                        {Array.isArray(orders) && orders.length > 0 && (
                            reorderedOrders.map((order, index) => (
                                <div key={order.id}>
                                    <OrderItemDragAndDrop
                                        order={order}
                                        index={index}
                                        handleDragEnd={handleDragEnd}
                                        colorPalette={colorPalette}
                                        hexToShadow={hexToShadow}
                                        sessionData={sessionData}
                                        getOrder={getOrder}
                                    />
                                </div>
                            ))
                        )}
                    </Grid>
                </Grid>
            </DndProvider>
        </>
    );
};

export default EditOrder;
