import axios from "axios"
import React, {useEffect, useRef, useState} from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {Editor} from "@tinymce/tinymce-react";
import ShowSnack from "../../util/ShowSnack";
import { CSSTransition } from 'react-transition-group';
import SaveIcon from '@mui/icons-material/Save';
import {CircularProgress} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

export default function EditField({ content, setContent, fieldType, fieldId, isEditing, setIsEditing })
{
    const editorRef = useRef(null);
    const [inputs, setInputs] = useState([]);
    const [snackOpen, setSnackOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        processFieldType();
    }, []);

    function processFieldType() {
        setInputs(values => ({ ...values, content: content }));
        if (fieldType === 'message') {

        }
    }

    function handleSave() {
        if (fieldType === 'message') {
            saveMessage();
        } else if (fieldType === 'topic') {
            saveTopic();
        } else if (fieldType === 'comment') {
            saveComment();
        }
    }

    const saveMessage = async(e) => {
        setIsLoading(true);
        const formData = new FormData();
        for (const [key, value] of Object.entries(inputs)) {
            formData.append(key, value);
        }
        formData.append('message_id', fieldId);
        axios.post('/api/edit/message', formData)
            .then(function (response) {
                if (response.data === 1) {
                    setContent(inputs.content);
                    setIsEditing(false);
                    setSnackOpen(true);
                    setMessage('Változások elmentve!');
                    setSeverity('success');
                } else {
                    setSnackOpen(true);
                    setMessage('Sikertelen művelet!');
                    setSeverity('error');
                    setIsLoading(false);
                }
            });
    };

    const saveTopic = async(e) => {
        setIsLoading(true);
        const formData = new FormData();
        for (const [key, value] of Object.entries(inputs)) {
            formData.append(key, value);
        }
        formData.append('topic_id', fieldId);
        axios.post('/api/edit/topic', formData)
            .then(function (response) {
                if (response.data === 1) {
                    setContent(inputs.content);
                    setIsEditing(false);
                } else {
                    setSnackOpen(true);
                    setMessage('Sikertelen művelet!');
                    setSeverity('error');
                    setIsLoading(false);
                }
            });
    };

    const saveComment = async(e) => {
        setIsLoading(true);
        const formData = new FormData();
        for (const [key, value] of Object.entries(inputs)) {
            formData.append(key, value);
        }
        formData.append('comment_id', fieldId);
        axios.post('/api/edit/comment', formData)
            .then(function (response) {
                if (response.data === 1) {
                    setContent(inputs.content);
                    setIsEditing(false);
                } else {
                    setSnackOpen(true);
                    setMessage('Sikertelen művelet!');
                    setSeverity('error');
                    setIsLoading(false);
                }
            });
    };

    return (
        <>
                <Grid>
                    <Editor
                        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                        onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={content}
                        onEditorChange={(newValue, editor) => {
                            setInputs(values => ({ ...values, content: newValue }));
                        }}
                        init={{
                            height: 210,
                            menubar: false,
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                            ],
                            toolbar: 'undo redo | blocks | ' +
                                'bold italic forecolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                    />
                </Grid>
                <Grid container>
                    <Grid item xs align={"right"} sx={{ mb: 2, mt: 2, ml: 2, mr: 2 }}>
                        <Button
                            onClick={() => handleSave()}
                            color={"success"}
                            variant={"outlined"}
                            disabled={isLoading}
                            endIcon={isLoading ? <CircularProgress size={20} sx={{ml: 2}}/> : <SaveIcon sx={{ml: 2}}/>}
                        >
                            Mentés
                        </Button>
                    </Grid>
                </Grid>
            <ShowSnack snackOpen={snackOpen} setSnackOpen={setSnackOpen} message={message} severity={severity} />
        </>
    );
}
