import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import CancelIcon from '@mui/icons-material/Cancel';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import {CircularProgress, IconButton, Tooltip} from '@mui/material';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';

const stripHtmlTags = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || '';
};

const volumeIcons = ['low', 'medium', 'high'];

const TextToSpeech = ({ text, language = 'hu-HU', autoPlay = true }) => {
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [voice, setVoice] = useState(null);
    const [pitch, setPitch] = useState(1);
    const [rate, setRate] = useState(1);
    const [volume, setVolume] = useState(0.5);
    const synthesis = window.speechSynthesis;
    const [strippedText, setStrippedText] = useState(text);
    const [isActive, setIsActive] = useState(false);
    const [currentVolumeIconIndex, setCurrentVolumeIconIndex] = useState(0)

    useEffect(() => {
        setStrippedText(stripHtmlTags(text));
    }, [text]);

    useEffect(() => {
            if (autoPlay) {
                if (synthesis.speaking || synthesis.paused) {
                    synthesis.cancel();
                    setIsSpeaking(false);
                }
                if (strippedText !== '') {
                    if (volume == 0) {
                        return;
                    }
                    setIsSpeaking(true)
                    setIsActive(true);
                    speakText();
                }
            }
    }, [strippedText]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (isSpeaking) {
                setCurrentVolumeIconIndex((prevIndex) => (prevIndex + 1) % volumeIcons.length);
            }
        }, 2100); // Change volume icon every second

        return () => clearInterval(intervalId);
    }, [isSpeaking]);

    const speakText = async () => {
        return new Promise((resolve, reject) => {
            const utterance = new SpeechSynthesisUtterance(strippedText);

            utterance.lang = language;
            if (voice) {
                utterance.voice = voice;
            }
            utterance.pitch = pitch;
            utterance.rate = rate;
            utterance.volume = volume;

            utterance.onend = () => {
                setIsSpeaking(false);
                setIsActive(false);
                resolve();
            };

            utterance.onerror = (error) => {
                setIsSpeaking(false);
                reject(error);
            };

            synthesis.speak(utterance);
            setIsSpeaking(true);
        });
    };

    const handleSpeaking = async () => {
        if (synthesis.speaking) {
            synthesis.pause();
            setIsSpeaking(false);
        } else if (synthesis.paused) {
            synthesis.resume();
            setIsSpeaking(true);
        } else {
            try {
                await speakText();
            } catch (error) {
                console.error('Speech synthesis error:', error);
            }
        }
    };

    const handleMute = () => {
        if (synthesis.speaking) {
            synthesis.cancel();
        }
        if (isActive === false) {
            setIsActive(true);
        }
        if (isActive === true && volume === 0) {
            setVolume(0.5);
        }
        if (isActive === true && volume !== 0) {
            setIsActive(false)
        }
    };


    const stopSpeaking = () => {
        if (synthesis.speaking || synthesis.paused) {
            synthesis.cancel();
            setIsSpeaking(false);
        }
    };

    const closeTextToSpeech = () => {
        stopSpeaking();
        setIsActive(false);
    };

    const voiceChangeHandler = (event) => {
        const selectedVoice = event.target.value;
        const availableVoices = synthesis.getVoices();
        const voiceToSet = availableVoices.find((v) => v.name === selectedVoice);
        setVoice(voiceToSet);
    };

    if (!isActive) {
        return (
            <Tooltip title="Felolvasó" arrow sx={{ zIndex: 750}}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={() => setIsActive(true)}
                    sx={{ ml: 1, mr: 1 }}
                >
                    {volume == 0 ? <VolumeOffIcon color={"warning"}/> : (
                        volumeIcons[currentVolumeIconIndex] === 'high' || volume > 0.7 ? (
                            <VolumeUpIcon />
                        ) : volumeIcons[currentVolumeIconIndex] === 'medium' || volume > 0.3 ? (
                            <VolumeDownIcon />
                        ) : (
                            <VolumeMuteIcon />
                        )
                    )}
                </IconButton>
            </Tooltip>
        );
    }

    return (
        <Box
            className="add-shadow"
            sx={{ borderRadius: '16px' }}
        >
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleMute}
                sx={{ ml: 1, mr: 1 }}
            >
                {volume == 0 ? <VolumeOffIcon color={"error"}/> : (
                    volumeIcons[currentVolumeIconIndex] === 'high' || (volume > 0.7 && isSpeaking === false) ? (
                        <VolumeUpIcon color={isSpeaking ? "info" : "primary"}/>
                    ) : volumeIcons[currentVolumeIconIndex] === 'medium' || (volume > 0.3 && isSpeaking === false) ? (
                        <VolumeDownIcon color={isSpeaking ? "info" : "primary"}/>
                    ) : (
                        <VolumeMuteIcon color={isSpeaking ? "info" : "primary"}/>
                    )
                )}
            </IconButton>
            {isSpeaking && (
                <>
                    <IconButton
                        color="inherit"
                        edge="start"
                        onClick={handleSpeaking} disabled={isSpeaking}
                        sx={{ ml: 1, mr: 1 }}
                    >
                        <PauseIcon/>
                    </IconButton>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={stopSpeaking} disabled={!isSpeaking}
                        sx={{ ml: 1, mr: 1 }}
                    >
                        <StopIcon />
                    </IconButton>
                </>
            )}
            {!isSpeaking && (
                <>
                    <input
                        type="range"
                        min="0"
                        max="1"
                        step="0.1"
                        value={volume}
                        onChange={(e) => setVolume(e.target.value)}
                    />
                </>
            )}
            {!isSpeaking && (
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleSpeaking} disabled={isSpeaking}
                    sx={{ ml: 2, mr: 2 }}
                >
                    <PlayArrowIcon />
                </IconButton>
            )}
        </Box>
    );
};

export default TextToSpeech;
