import React, {useEffect, useState, useRef} from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ShowSnack from "../../util/ShowSnack";
import TitleBox from "../interface/TitleBox";
import GroupMenuBar from "./menubar/GroupMenuBar";

export default function EditGroup({ colorPalette, hexToShadow })
{
    const editorRef = useRef(null);
    const { id } = useParams();
    const [groupContent, setGroupContent] = useState('');
    const [groupData, setGroupData] = useState([]);
    const [inputs, setInputs] = useState([]);
    const [snackOpen, setSnackOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');

    const handleSubmit = async(e) => {
        e.preventDefault();
        const formData = new FormData();
        for (const [key, value] of Object.entries(inputs)) {
            formData.append(key, value);
        }
        formData.append('group_id', id);
        axios.post('/api/edit/group', formData)
            .then(function (response) {
                console.log(response.data);
                if (response.data  === 1) {
                    setSnackOpen(true);
                    setMessage('Sikeres mentés!');
                    setSeverity('success');
                } else {
                    setSnackOpen(true);
                    setMessage('Sikertelen művelet!');
                    setSeverity('error');
                }
            });
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }));
    }

    useEffect(() => {
        getLesson();
    }, [id]);

    function getLesson() {
        axios
            .get(`/api/get/group/${id}`)
            .then(function (response) {
                setGroupContent(response.data.content);
                setGroupData(response.data);
                setInputs(values => ({ ...values, title: response.data.title }));
                setInputs(values => ({ ...values, content: response.data.content }));
            })
            .catch(function (error) {
                console.error('Error fetching data:', error);
            });
    }

    return (
        <>
            {groupData !== [] && <GroupMenuBar groupId={id} colorPalette={colorPalette} hexToShadow={hexToShadow} />}
            <TitleBox title={`Csoport szerkesztése`} />
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <TextField
                    fullWidth
                    id="title"
                    value={inputs.title}
                    name="title"
                    onChange={handleChange}
                />
                <h5>Leírás</h5>
                <Editor
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                    onInit={(evt, editor) => editorRef.current = editor}
                    initialValue={groupContent}
                    onEditorChange={(newValue, editor) => {
                        setInputs(values => ({ ...values, content: newValue }));
                    }}
                    init={{
                        height: 300,
                        menubar: false,
                        plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                            'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                        ],
                        toolbar: 'undo redo | blocks | ' +
                            'bold italic forecolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="success"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Mentés
                </Button>
            </Box>
            <ShowSnack snackOpen={snackOpen} setSnackOpen={setSnackOpen} message={message} severity={severity} />
        </>
    );
}
