import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Defs, LinearGradient, Stop } from 'recharts';
import Grid from "@mui/material/Grid";

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{ backgroundColor: "#000"}}>
                <p>{`${payload[0].payload.content}`}</p>
            </div>
        );
    }
    return null;
};

const LineGraph = ({ data }) => {
    return (
        <Grid container style={{ width: '99%', height: 350 }}>
            <Grid item xs={12}>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={data}>
                        <defs>
                            <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
                                <stop offset="0%" stopColor="green" />
                                <stop offset="50%" stopColor="yellow" />
                                <stop offset="100%" stopColor="red" />
                            </linearGradient>
                        </defs>
                        <XAxis dataKey="name" />
                        <YAxis domain={[0, 100]} />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip content={<CustomTooltip />} />
                        <Line
                            type="monotone"
                            dataKey="percent"
                            strokeWidth={5}
                            dot={false}
                            stroke="url(#gradient)" // Use the linear gradient as the stroke color
                        />
                    </LineChart>
                </ResponsiveContainer>
            </Grid>
        </Grid>
    );
};

export default LineGraph;
