import Avatar from "@mui/material/Avatar";
import React from "react";
import {Tooltip} from "@mui/material";
import StringAvatar from "./StringAvatar";

export default function UserAvatar({ user, bgColor = 'default' })
{
    if (user.picture !== undefined) {
        return (
            <Tooltip title={user.name} arrow>
                <Avatar alt={user.name} src={user.picture} />
            </Tooltip>
        );
    }

    return (
        <StringAvatar userName={user.name} bgColor={bgColor} />
    );
}
