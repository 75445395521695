import { createContext, useContext, useEffect, useState } from 'react';
import axios from "axios";

const SessionDataContext = createContext();

export function SessionDataProvider({ children }) {
    const [sessionData, setSessionData] = useState({
        userId: 0,
        rights: 0,
        logged: 0,
        isLogged: false,
        isUserAdmin: false,
        isUserTeacher: false,
        username: '',
        email: '',
        picture: '',
        friends: [],
        session_users: [],
        notes: '',
        reg_complete: 1,
        selectedGroup: 0,
        selected_users: [],
        darkMode: localStorage.getItem('darkMode') === 'true',
        shadows: localStorage.getItem('shadows') === 'true',
        hash: localStorage.getItem('hash') ?? null,
        isSidebarVisible: true,
        isSidebarDisabled: false,
    });

    const isOwner = (item) => {
        return sessionData.userId === item.user_id;
    };

    const hasEditRights = (item) => {
        return sessionData.userId === item.user_id || sessionData.isUserAdmin;
    };

    const isSelectedUser = (user_id) => {
        return sessionData.selected_users.includes(user_id);
    }

    const toggleSelectedUser = (user_id) => {
        if (isSelectedUser(user_id)) {
            setSessionData((prevData) => ({
                ...prevData,
                selected_users: prevData.selected_users.filter(item => item !== user_id),
            }));
        } else {
            setSessionData((prevData) => ({
                ...prevData,
                selected_users: [...prevData.selected_users, user_id],
            }));
        }
    }

    const newHash = () => {
        const newHashValue = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        localStorage.setItem('hash', newHashValue);
        const formData = new FormData();
        formData.append('hash', newHashValue);
        axios.post('/api/save/hash', formData);
        return newHashValue;
    };

    const toggleSidebar = () => {
        setSessionData((prevData) => ({
            ...prevData,
            isSidebarVisible: !prevData.isSidebarVisible,
        }));
    };

    const toggleSidebarOff = () => {
        setSessionData((prevData) => ({
            ...prevData,
            isSidebarVisible: false,
        }));
    };

    const isOnPath = (path) => {
        return window.location.pathname.includes(path);
    };

    function hexToShadow(hex, opacity = 0.7, size = 25) {
        const darkShadowOp = 0.2;
        const usedOpacity = sessionData.darkMode ? darkShadowOp : opacity;
        if (!sessionData.shadows) {
            return 'none';
        }

        if (hex) {
            hex = hex.replace('#', '');
            const bigint = parseInt(hex, 16);
            const r = (bigint >> 16) & 255;
            const g = (bigint >> 8) & 255;
            const b = bigint & 255;

            return `5px 5px ${size}px rgba(${r}, ${g}, ${b}, ${usedOpacity})`;
        }
    }

    useEffect(() => {
        setSessionData((prevData) => ({
            ...prevData,
            isUserAdmin: prevData.rights === 5,
            isUserTeacher: prevData.rights >= 3,
            isLogged: prevData.logged === 1,
            hash: prevData.hash ?? newHash(),
        }));
    }, [sessionData.userId]);

    return (
        <SessionDataContext.Provider
            value={{
                sessionData,
                setSessionData,
                isOwner,
                hasEditRights,
                isSelectedUser,
                toggleSelectedUser,
                newHash,
                toggleSidebar,
                toggleSidebarOff,
                isOnPath,
                hexToShadow,
            }}
        >
            {children}
        </SessionDataContext.Provider>
    );
}

export function useSessionData() {
    const context = useContext(SessionDataContext);
    if (!context) {
        throw new Error('useSessionData must be used within a SessionDataProvider');
    }
    return context;
}
