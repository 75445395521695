import axios from "axios"
import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import {Box} from "@mui/system";

function renderHTMLContent(htmlString) {
    return { __html: htmlString };
}

const columns = [
    { field: 'id', headerName: 'ID', width: 70},
    { field: 'content',
        headerName: 'Kérdés',
        flex: 3,
        renderCell: (params) => (
            <div dangerouslySetInnerHTML={renderHTMLContent(params.row.content)} />
        ),
    },
    {
        field: 'actions',
        headerName: '',
        flex: 1,
        renderCell: (params) => (
            <>
                <Button component={Link} to={`/edit/question/${params.row.id}`} fullWidth variant={"outlined"} color={"info"}>Szerkesztés</Button>
            </>
        ),
    },
];

export default function TestQuestionList({ testId, colorPalette })
{
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        getQuestions();
    }, []);

    function getQuestions() {
        axios
            .get(`/api/list/testQuestions/${testId}`)
            .then(function (response) {
                setQuestions(response.data);
            })
            .catch(function (error) {
                console.error('Error fetching data:', error);
            });
    }

    if (questions.length === 0) return null;

    return (
    <Box>
        <DataGrid
            sx={{ color: colorPalette.text }}
            rows={questions}
            columns={columns}
            pageSizeOptions={[5, 10, 100]}
        />
    </Box>
    )
}
