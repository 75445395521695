import React, {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import axios from "axios";
import {Box} from "@mui/system";
import {useSessionData} from "../../auth/SessionDataProvider";

const Question = ({ questionData, currentQuestion, setCurrentQuestion, questionCount, colorPalette }) => {
    const { content, answers } = questionData;
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [timer, setTimer] = useState(0);
    const [intervalId, setIntervalId] = useState(null);
    const { hexToShadow } = useSessionData();

    useEffect(() => {
        startTimer();
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    function startTimer() {
        clearInterval(intervalId);
        setTimer(0);
        const newIntervalId = setInterval(() => {
            setTimer((prevTimer) => prevTimer + 1);
        }, 1000);
        setIntervalId(newIntervalId);
    }

    function nextQuestion() {
        const lastQuestion = currentQuestion === questionCount - 1;
        if (lastQuestion) {
            saveAnswer(lastQuestion);
            clearInterval(intervalId);
            setIntervalId(null);
            setCurrentQuestion(currentQuestion + 1);
            return;
        }
        saveAnswer(lastQuestion);
        clearInterval(intervalId);
        setIntervalId(null);
        startTimer();
        setCurrentQuestion(currentQuestion + 1);
        setSelectedAnswer(null);
    }

    function saveAnswer(lastQuestion) {
        const formData = new FormData();
        formData.append('answer_id', selectedAnswer);
        formData.append('answer_time', timer)
        formData.append('last_question', lastQuestion)
        axios.post('/api/save/answer', formData);
    }

    function selectAnswer(answer) {
        setSelectedAnswer(answer.id);
    }

    return (
        <>
            <Box className="chat-bubble" sx={{ mt: 2, mb: 2 }} >
                <Box className="chat-bubble-content" bgcolor={colorPalette.paper} sx={{ color: colorPalette.text, padding: '20px' }} >
                <div dangerouslySetInnerHTML={{ __html: content }} align={"center"}></div>
                </Box>
            </Box>
            {answers.map((answer, index) => (
                <Box align={"center"}>
                    <Button
                        key={index}
                        variant={answer.id === selectedAnswer ? "contained" : "outlined"}
                        sx={{mt: 1, mb: 1, width: '90%'}}
                        onClick={() => selectAnswer(answer)}>
                        {answer.answer}
                    </Button>
                </Box>
            ))}
            <Box align={"center"}>
                <Button
                    fullWidth
                    variant="contained"
                    color="success"
                    onClick={nextQuestion}
                    disabled={selectedAnswer === null}
                    sx={{ mt: 5, mb: 3, width: '90%' }}
                >
                    Következő kérdés
                </Button>
            </Box>
        </>
    );
};

export default Question;
