import axios from "axios"
import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import Button from "@mui/material/Button";
import {Link, useParams} from "react-router-dom";

const columns = [
    { field: 'id', headerName: 'ID', width: 70},
    { field: 'title', headerName: 'Title', flex: 1 },
    {
        field: 'actions',
        headerName: '',
        width: 150,
        renderCell: (params) => (
            <>
                <Button component={Link} to={`/admin/list/chapters/${params.row.id}`} style={{marginRight: "10px"}}>Megnyitás</Button>
                {/*<button onClick={() => deleteUser(params.row.id)}>Delete</button>*/}
            </>
        ),
    },
];

export default function TopicList()
{
    const [feed, setFeed] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        getFeed();
    }, [id]);

    function getFeed() {
        axios
            .get(`/api/list/topics/${id}`)
            .then(function (response) {
                setFeed(response.data);
            })
            .catch(function (error) {
                console.error('Error fetching data:', error);
            });
    }

    return (
    <div style={{ height: '100%', width: '100%' }}>
        <DataGrid
            rows={feed}
            columns={columns}
            pageSizeOptions={[5, 10]}
            fullWidth
        />
    </div>
    )
}
