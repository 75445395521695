import React from 'react';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {useNavigate} from "react-router-dom";
import StringAvatar from "./StringAvatar";
import {useSessionData} from "../auth/SessionDataProvider";
import {IconButton} from "@mui/material";
import UserAvatar from "./UserAvatar";
const UserCardSmall = ({ userId, colorPalette }) => {
    const navigate = useNavigate();
    const { hexToShadow, sessionData } = useSessionData();
    const user = sessionData.session_users[userId];

    return (
        <Box
            className={`sidebar-profile-container`}
            bgcolor={colorPalette.paper}
            sx={{ boxShadow: hexToShadow(colorPalette.paper) }}
        >
            <Box className="article_box_header_link" bgcolor={colorPalette.paper}>
                <Grid container>
                    <Grid item sx={{ flex: 1}}>
                        <UserAvatar user={user} />
                    </Grid>
                    <Grid item sx={{ flex: 3}}>
                        {user.name}
                    </Grid>
                    <Grid item sx={{ flex: 1}} align={"right"}>
                        <IconButton
                            key={userId}
                            variant="outlined"
                            color={"success"}
                            align={"right"}
                            onClick={() => navigate(`/user/profile/${userId}`)}
                        >
                            <KeyboardArrowRightIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );

};

export default UserCardSmall;
