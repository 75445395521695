import axios from "axios"
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {useSessionData} from "../../auth/SessionDataProvider";
import ShowFeedTestResults from "../feed/ShowFeedTestResults";
import LineGraph from "../../util/LineGraph";

export default function TestResultsLatest({ isSidebarVisible = true, colorPalette })
{
    const [feed, setFeed] = useState([]);
    const [data, setData] = useState([{}]);
    const { hexToShadow } = useSessionData();

    useEffect(() => {
        getFeed();
    }, []);

    function getFeed()
    {
        axios.get('/api/user/feedLatest').then(function (response) {
            const feedArray = Object.keys(response.data).map((key) => ({
                id: key,
                ...response.data[key],
            }));
            setFeed(feedArray);
            setData(feedArray.slice(0, 9).map((item) => ({
                name: item.date,
                content: item.content,
                percent: item.percent,
            })).reverse());
        });
    }

    return (
        <>
            <LineGraph data={data}/>
            <Grid container spacing={2}>
                {feed.slice(0, 9).map((item, index) => (
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        lg={isSidebarVisible ? 4 : 4}
                        key={item.id}
                    >
                        <ShowFeedTestResults key={index} item={item} colorPalette={colorPalette} hexToShadow={hexToShadow} />
                    </Grid>
                ))}
            </Grid>
        </>
    );
}
