import React, {useEffect} from "react";
import { useLocation } from "react-router-dom";
import Container from '@mui/material/Container';
import Grid from "@mui/material/Grid";
import axios from "axios";

export default function RequireSub({ children, subscription })
{
    const location = useLocation();
    const [sub, setSub] = React.useState(0);

    useEffect(() => {
        axios.post('/api/user/getSubscriptionStatus')
            .then((result) => {
                if (result.data !== false) {
                    setSub(result.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const isRequireSubscriptionPage =
        location.pathname.startsWith("/category") ||
        location.pathname.startsWith("/wild") ||
        location.pathname.startsWith("/endless");

    if (sub) {
        return children;
    } else if (isRequireSubscriptionPage) {
        return (
            <Container>
                <Grid item xs={12}>
                    <h3>
                        You need to be a subscribed member to watch this content<br/>
                    </h3>
                </Grid>
            </Container>
        );
    }

    return null;
}
