import React, {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import SchoolIcon from '@mui/icons-material/School';
import PersonIcon from '@mui/icons-material/Person';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import {useSessionData} from "../../auth/SessionDataProvider";
import TextField from "@mui/material/TextField";
import axios from "axios";
import {CircularProgress} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import {useNavigate} from "react-router-dom";

export default function CreateUser({ colorPalette, setIsSidebarVisible, getSessionData }) {
    const { sessionData, setSessionData } = useSessionData();
    const navigate = useNavigate();
    const [inputs, setInputs] = useState([]);
    const [isStudentSelected, setIsStudentSelected] = useState(false);
    const [isTeacherSelected, setIsTeacherSelected] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [username, setUsername] = useState([]);

    useEffect(() => {
        setIsSidebarVisible(false);
        setSessionData((prevData) => ({
            ...prevData,
            isSidebarDisabled: true,
        }));
        if (sessionData.username !== null) {
            const parsedUsername = sessionData.username.split(' ');
            setInputs(values => ({
                ...values,
                family_name: parsedUsername[0] || '',
                given_name: parsedUsername[1] || '',
            }));
        }
    }, [sessionData.username]);

    const finishRegistration = async(e) => {
        setIsLoading(true);
        const formData = new FormData();
        const applyAsTeacher = isTeacherSelected ? 1 : 0;
        for (const [key, value] of Object.entries(inputs)) {
            formData.append(key, value);
        }
        formData.append('apply_as_teacher', applyAsTeacher);
        axios.post('/api/user/finishRegistration', formData)
            .then(function (response) {
                getSessionData();
            });
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }));
    };

    const handleStudentSelection = (event) => {
        setIsStudentSelected(event.target.checked);
    };

    const handleTeacherSelection = (event) => {
        setIsTeacherSelected(event.target.checked);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    }

    if (sessionData.reg_complete === 1) {
        setIsSidebarVisible(true);
        setSessionData((prevData) => ({
            ...prevData,
            isSidebarDisabled: false,
        }));
        navigate('/home');
    }

    if (currentPage === 2) {
        return (
            <Grid container justifyContent="center" alignItems="center">
                <Box align="center" width="50%">
                    <Grid container direction="column" alignItems="center">
                        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5" sx={{ mt: 2, mb: 2 }}>
                            Nézd át az alapvető adataid
                            <Divider sx={{ mt: 2, mb: 2 }} />
                        </Typography>
                    </Grid>
                    <Grid container direction="column" alignItems="center">
                        <TextField id="email" name="email" label="Email cím" variant="outlined" value={sessionData.email} sx={{ mt: 2, mb: 2 }} fullWidth disabled={true}/>
                        <TextField id="family_name" name="family_name" onChange={handleChange} label="Vezetéknév" variant="outlined" value={inputs.family_name} sx={{ mt: 2, mb: 2 }} fullWidth/>
                        <TextField id="given_name" name="given_name" onChange={handleChange} label="Keresztnév" variant="outlined" value={inputs.given_name} sx={{ mt: 2, mb: 2 }} fullWidth/>
                    </Grid>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            variant="contained"
                            color="success"
                            sx={{ pt: 2, pb: 2, pl: 10, pr: 10, m: 3 }}
                            disabled={isLoading}
                            onClick={finishRegistration}
                            endIcon={isLoading ? <CircularProgress size={20} sx={{ ml: 2 }}/> : <SendIcon sx={{ ml: 2 }} />}
                        >
                            Rendben vannak az adataim
                        </Button>
                    </Grid>
                </Box>
            </Grid>
        );
    }

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Box align="center" width="75%">
                <Grid container direction="column" alignItems="center">
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" sx={{ mt: 2, mb: 2 }}>
                        Válaszd ki a regisztráció típusát:
                        <Divider sx={{ mt: 2, mb: 2 }} />
                    </Typography>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item xs={5} sx={{ m: 2 }}>
                        <Box className="chat-bubble-meta" bgcolor={colorPalette.dark} color={colorPalette.text_on_dark}>
                            <Typography variant="h6" color={colorPalette.text_on_dark}>
                                Tanuló <PersonIcon sx={{ ml: 2 }} />
                            </Typography>
                        </Box>
                        <Box className="chat-bubble-content" bgcolor={colorPalette.paper} sx={{ p: 2 }} height={"100%"}>
                            <ul>
                                <li>Ingyenes regisztráció</li>
                                <li>Azonnali kezdés</li>
                                <li>AI asszisztens</li>
                            </ul>
                        </Box>
                        <Box bgcolor={colorPalette.paper} align="center">
                            <Checkbox checked={isStudentSelected} onChange={handleStudentSelection} />
                            Elfogadom a felhasználási feltételeket!
                            <Divider/>
                            <Button
                                variant="contained"
                                color="success"
                                sx={{ p: 2, mt: 3, mb: 3 }}
                                disabled={!isStudentSelected}
                                onClick={handleNextPage}
                            >
                                Tanulni szeretnék
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={5} sx={{ m: 2 }}>
                        <Box className="chat-bubble-meta" bgcolor={colorPalette.dark} color={colorPalette.text_on_dark}>
                            <Typography variant="h6" color={colorPalette.text_on_dark}>
                                Tanár <SchoolIcon sx={{ ml: 2 }} />
                            </Typography>
                        </Box>
                        <Box className="chat-bubble-content" bgcolor={colorPalette.paper} sx={{ p: 2 }} height={"100%"}>
                            <ul>
                                <li>Ingyenes regisztráció</li>
                                <li>Több száz NAT alapján kidolgozott tananyag</li>
                                <li>AI asszisztens</li>
                                <li>Automata teszt kiértékelés</li>
                                <li>Jóváhagyás szükséges</li>
                            </ul>
                        </Box>
                        <Box bgcolor={colorPalette.paper} align="center">
                            <Checkbox checked={isTeacherSelected} onChange={handleTeacherSelection} />
                            Elfogadom a felhasználási feltételeket!
                            <Divider/>
                            <Button
                                variant="contained"
                                color="success"
                                sx={{ p: 2, mt: 3, mb: 3 }}
                                disabled={!isTeacherSelected}
                                onClick={handleNextPage}
                            >
                                Tanítani szeretnék
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );
}
