import * as React from 'react';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import { Chip } from "@mui/material";
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import axios from "axios";
import { useEffect } from "react";
import {useNavigate} from "react-router-dom";
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CourseMenuBar from "./menubar/CourseMenuBar";

function DraggableTreeItem({ node, moveItem, ...props }) {
    const isLesson = node.type === 'lesson';
    const isFolder = node.type === 'folder';
    const isFile = node.type === 'file';
    const isPdf = node.type === 'pdf';
    const isTrash = node.type === 'trash';
    const hasChildren = props.children.length > 0;
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'treeItem',
        item: { id: node.id },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    const [, drop] = useDrop(() => ({
        accept: 'treeItem',
        drop: (item, monitor) => {
            if (monitor.didDrop()) {
                // If the item drop is already handled (by a child),
                // then we don't proceed further.
                return;
            }
            moveItem(item.id, node.id);
        },
    }));

    const opacity = isDragging ? 0.5 : 1;

    return (
        <div ref={drop}>
            <div ref={drag}>
                <TreeItem
                    {...props}
                    style={{ opacity }}
                    nodeId={node.id.toString()}
                    label={
                        <Chip
                            key={node.id}
                            label={
                                <>
                                    {isLesson && <TextSnippetOutlinedIcon />}
                                    {isFile && <AttachFileOutlinedIcon />}
                                    {isPdf && <PictureAsPdfIcon />}
                                    {isTrash && <DeleteForeverOutlinedIcon />}
                                    {(!hasChildren && isFolder) && <FolderOpenIcon />}
                                    &nbsp;{node.title}
                                </>
                            }
                            onClick={() => props.onClick(node.id, node.title, node.type, node.lesson_id)}
                            sx={{ ml: 1, mr: 1, textSizeAdjust: 'auto', fontSize: '1.2rem', fontWeight: 'bold'  }}
                        />
                    }
                />
            </div>
        </div>
    );
}

function CourseManager({ colorPalette }) {
    const [treeItems, setTreeItems] = React.useState([]);
    const [courses, setCourses] = React.useState([]);
    const [selectedTitle, setSelectedTitle] = React.useState('');
    const [selectedId, setSelectedId] = React.useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        getCourses();
    }, []);

    function handleTagClick(id, title, type, lesson_id = 0) {
        if (type === 'folder') {
            setSelectedTitle(title);
            setSelectedId(id);
        }
        if (type === 'lesson') {
            navigate(`/edit/lesson/${lesson_id}`);
        }
    }

    function getCourses() {
        axios.get('/api/get/fullCourse').then(function (response) {
            setCourses(response.data);
        });
    }

    function moveItem(draggedId, targetId) {
        if (draggedId === targetId) {
            return;
        }
        const formData = new FormData();
        formData.append('move_id', draggedId);
        formData.append('parent_id', targetId);
        axios.post(`/api/course/moveElement`, formData)
            .then(function (response) {
                if (response.data === 1) {
                    getCourses();
                }
            });
    }

    React.useEffect(() => {
        const generateTreeItems = (items, parentId = 0) => {
            if (items.length === 0) {
                return ['Betöltés...'];
            }

            const filteredItems = [];
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                if (item.parent_id === parentId) {
                    const childItems = generateTreeItems(items, item.id);
                    try {
                        filteredItems.push(
                            <DraggableTreeItem
                                key={item.id}
                                node={item}
                                onClick={(id, title, type, lesson_id) => handleTagClick(id, title, type, lesson_id)}
                                moveItem={moveItem}
                            >
                                {childItems}
                            </DraggableTreeItem>
                        );
                    } catch (error) {
                        console.error("Error while creating DraggableTreeItem:", error);
                    }
                }
            }

            return filteredItems;
        };

        const updatedTreeItems = generateTreeItems(courses);
        setTreeItems(updatedTreeItems);
    }, [courses]);

    return (
        <>
            <CourseMenuBar parentId={selectedId} title={selectedTitle} colorPalette={colorPalette} getCourses={getCourses}/>
            <DndProvider backend={HTML5Backend}>
                <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={<FolderOpenIcon />}
                    defaultExpandIcon={<FolderIcon />}
                    sx={{ height: '100%', flexGrow: 1, maxWidth: '100%', overflowY: 'auto', textAlign: 'left' }}
                >
                    {treeItems}
                </TreeView>
            </DndProvider>
        </>
    );
}

export default CourseManager;
