import React, {useEffect, useState, useRef, useCallback} from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ShowSnack from '../../../util/ShowSnack';
import LessonMenuBar from './LessonMenuBar';
import 'react-image-crop/dist/ReactCrop.css';
import Grid from "@mui/material/Grid";
import ImageUpload from "../../../util/ImageUpload";

export default function LessonEditor({ colorPalette, isSidebarVisible, setIsSidebarVisible }) {
    const editorRef = useRef(null);
    const { id } = useParams();
    const [lessonContent, setLessonContent] = useState('');
    const [lessonData, setLessonData] = useState([]);
    const [inputs, setInputs] = useState({ title: '', content: '' });
    const [snackOpen, setSnackOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Send the other form data (title, content, etc.) to the API
        const formData = new FormData();
        for (const [key, value] of Object.entries(inputs)) {
            formData.append(key, value);
        }
        formData.append('lesson_id', id);

        try {
            const response = await axios.post('/api/edit/lesson', formData);
            console.log(response.data);
            if (response.data === 1) {
                setSnackOpen(true);
                setMessage('Sikeres mentés!');
                setSeverity('success');
            } else {
                setSnackOpen(true);
                setMessage('Sikertelen művelet!');
                setSeverity('error');
            }
        } catch (error) {
            console.error('Error submitting form data:', error);
        }
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }));
    };

    const getLesson = useCallback(() => {
        axios
            .get(`/api/get/lesson/${id}`)
            .then(function (response) {
                setLessonContent(response.data.content);
                setLessonData(response.data);
                setInputs({ title: response.data.title, content: response.data.content });
            })
            .catch(function (error) {
                console.error('Error fetching data:', error);
            });
    }, [id]);

    useEffect(() => {
        getLesson();
    }, [getLesson]);

    return (
        <Grid container flex={1}>
            <LessonMenuBar parent={lessonData} colorPalette={colorPalette} />
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }} encType="multipart/form-data" width={"100%"} >
                <TextField
                    fullWidth
                    id="title"
                    value={inputs.title}
                    name="title"
                    onChange={handleChange}
                />
                <ImageUpload lessonData={lessonData} getLesson={getLesson} setIsSidebarVisible={setIsSidebarVisible} />
                <Box width={"100%"}>
                    {lessonData.image && (
                        <img src={`/${lessonData.image}`} alt="Lesson header" width={"100%"}/>
                    )}
                </Box>
                <Editor
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                    onInit={(evt, editor) => editorRef.current = editor}
                    initialValue={lessonContent}
                    onEditorChange={(newValue, editor) => {
                        setInputs(values => ({ ...values, content: newValue }));
                    }}
                    init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                            'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                        ],
                        toolbar: 'undo redo | blocks | ' +
                            'bold italic forecolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:18px }'
                    }}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="success"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Mentés
                </Button>
            </Box>
            <ShowSnack snackOpen={snackOpen} setSnackOpen={setSnackOpen} message={message} severity={severity} />
        </Grid>
    );
}
