import React, { useEffect, useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import ShowSnack from "./ShowSnack";
import CustomFileInput from "./CustomFileInput";

function ImageUpload({ lessonData, getLesson, setIsSidebarVisible }) {
    const [imageSrc, setImageSrc] = useState(null);
    const baseWidth = 1500;
    const [crop, setCrop] = useState({ unit: 'px', width: baseWidth, aspect: 21 / 9 });
    const [croppedImage, setCroppedImage] = useState(null);
    const [snackOpen, setSnackOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const imageRef = useRef(null);

    useEffect(() => {
        if (imageSrc) {
            setIsSidebarVisible(false);
            const image = new Image();
            image.src = imageSrc;
            image.onload = () => {
                imageRef.current = image;

                setCrop({
                    unit: '%',
                    width: 100,
                    height: 43,
                    aspect: 21 / 9,
                });
            };
        }
    }, [imageSrc, setIsSidebarVisible]);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageSrc(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const resizeImage = (image, width) => {
        return new Promise((resolve) => {
            const canvas = document.createElement('canvas');
            const aspectRatio = image.width / image.height;
            const height = width / aspectRatio;

            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');
            ctx.imageSmoothingEnabled = true;
            ctx.webkitImageSmoothingEnabled = true;
            ctx.mozImageSmoothingEnabled = true;
            ctx.msImageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';
            ctx.drawImage(image, 0, 0, width, height);

            canvas.toBlob((blob) => {
                resolve(blob);
            }, 'image/jpeg', 0.75);
        });
    };

    const getCroppedImageBlob = (image, crop, resizedBlob ) => {
        return new Promise((resolve) => {
            const canvas = document.createElement('canvas');
            canvas.width = baseWidth;
            canvas.height = (crop.height / crop.width) * baseWidth;

            const ctx = canvas.getContext('2d');
            ctx.imageSmoothingEnabled = true;
            ctx.webkitImageSmoothingEnabled = true;
            ctx.mozImageSmoothingEnabled = true;
            ctx.msImageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';

            const resizedImage = new Image();
            resizedImage.src = URL.createObjectURL(resizedBlob);

            resizedImage.onload = () => {
                const scaleX = resizedImage.naturalWidth / resizedImage.width;
                const scaleY = resizedImage.naturalHeight / resizedImage.height;

                ctx.drawImage(
                    resizedImage,
                    crop.x * scaleX,
                    crop.y * scaleY,
                    crop.width * scaleX,
                    crop.height * scaleY,
                    0,
                    0,
                    canvas.width,
                    canvas.height
                );

                canvas.toBlob((blob) => {
                    resolve(blob);
                }, 'image/jpeg', 0.9);
            };
        });
    };


    const handleCropComplete = async (crop) => {
        if (imageRef.current && crop.width && crop.height) {
            const image = imageRef.current;

            try {
                const resizedBlob = await resizeImage(image, baseWidth);
                const croppedBlob = await getCroppedImageBlob(image, crop, resizedBlob);

                setCroppedImage(croppedBlob); // Set the cropped image as blob
            } catch (error) {
                console.error('Error cropping image:', error);
            }
        } else {
            console.error('Image reference not loaded properly.');
        }
    };

    const handleImageUpload = async () => {
        if (croppedImage) {
            const formData = new FormData();
            formData.append('image', croppedImage, 'cropped_image.jpeg');
            formData.append('lesson_id', lessonData.id);
            try {
                const response = await axios.post('/api/upload/lessonImage', formData);
                if (response.data === 1) {
                    setImageSrc(null);
                    setCroppedImage(null);
                    getLesson();
                    setSnackOpen(true);
                    setMessage('Sikeres mentés!');
                    setSeverity('success');
                }
            } catch (error) {
                setSnackOpen(true);
                setMessage('Sikertelen művelet!');
                setSeverity('error');
                console.error('Error uploading image:', error);
            }
        }
    };


    return (
        <Grid container sx={{ mt: 1, mb: 1 }} spacing={2}>
            <Grid item xs={9}>
                <CustomFileInput handleImageChange={handleImageChange} customText={"Tölts fel vagy húzz ide fejlécképet"} />
            </Grid>
            <Grid item xs={3}>
                <Button
                    onClick={handleImageUpload}
                    fullWidth
                    variant="contained"
                    color="success"
                    disabled={!croppedImage}
                    sx={{ p: 2 }}
                >
                    Fejléckép mentése
                </Button>
            </Grid>
            <Grid item sx={{ width: baseWidth }}>
                {imageSrc && (
                    <ReactCrop
                        src={imageSrc}
                        crop={crop}
                        onChange={(newCrop) => setCrop(newCrop)}
                        onComplete={handleCropComplete}
                        aspect={21 / 9}
                        minWidth={baseWidth}
                        width={baseWidth}
                    >
                        <img src={imageSrc} alt="Crop" style={{ width: baseWidth }}/>
                    </ReactCrop>
                )}
            </Grid>
            <ShowSnack snackOpen={snackOpen} setSnackOpen={setSnackOpen} message={message} severity={severity} />
        </Grid>
    );
}

export default ImageUpload;
