import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SendIcon from '@mui/icons-material/Send';
import Divider from "@mui/material/Divider";
import {useNavigate} from "react-router-dom";
import FolderIcon from "@mui/icons-material/Folder";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import axios from "axios";

export default function CourseStructureEditorElement({ item, setParentId, groupId, getOrder })
{
    const navigate = useNavigate();
    const handleClick = (id) => {
        if (item.type === 'folder') {
            setParentId(id);
        } else {
            // navigate(`/view/lesson/${item.lesson_id}`);
            addLessonToStructure();
        }
    };

    const addLessonToStructure = async(e) => {
        const formData = new FormData();
        formData.append('lesson_id', item.lesson_id);
        formData.append('group_id', groupId);
        formData.append('title', item.title);
        axios.post('/api/group/addLesson', formData)
            .then(function (response) {
                if (response.data === 1) {
                    getOrder();
                }
            });
    };

    const getIcon = (type) => {
        switch (type) {
            case 'folder':
                return <FolderIcon sx={{mr: 2}}/>;
            case 'lesson':
                return <TextSnippetOutlinedIcon sx={{mr: 2, ml: 2}}/>;
            case 'file':
                return <AttachFileOutlinedIcon sx={{mr: 2}}/>;
            case 'pdf':
                return <PictureAsPdfIcon sx={{mr: 2}}/>;
            case 'trash':
                return <DeleteForeverOutlinedIcon sx={{mr: 2}}/>;
            default:
                return <SendIcon sx={{mr: 2}}/>;
        }
    }

    return (
        <Grid container>
            <Button
                key={item.id}
                onClick={() => handleClick(item.id)}
                sx={{ fontSize: '18px' }}
            >
                 {getIcon(item.type)} {item.title}
            </Button>
            <Divider/>
        </Grid>
    );
}
