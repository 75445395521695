import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import CreateFolder from './CreateFolder';
import CreateLesson from "./CreateLesson";
import {useSessionData} from "../../../auth/SessionDataProvider";

const CourseMenuBar = ({ parentId, title, getCourses, colorPalette }) => {
    const [activeButton, setActiveButton] = useState(null);
    const { hexToShadow } = useSessionData();

    const toggleButton = (buttonName) => {
        setActiveButton(activeButton === buttonName ? null : buttonName);
    };

    return (
        <Box
            className="animated-resize-height"
            sx={{
                boxShadow: hexToShadow(colorPalette.sidebar),
                height: activeButton ? '300px' : '70px',
            }}
        >
            <Button
                color="info"
                variant={activeButton === 'createFolder' ? 'contained' : 'outlined'}
                sx={{ ml: 2, mr: 2, mb: 2, mt: 2 }}
                onClick={() => toggleButton('createFolder')}
                endIcon={<AddIcon sx={{ ml: 2 }} />}
            >
                Új mappa
            </Button>
            <Button
                color="info"
                variant={activeButton === 'createLesson' ? 'contained' : 'outlined'}
                sx={{ mr: 2, mb: 2, mt: 2 }}
                onClick={() => toggleButton('createLesson')}
                endIcon={<AddIcon sx={{ ml: 2 }} />}
            >
                Új lecke
            </Button>
            {activeButton === 'createFolder' && <CreateFolder parentId={parentId} title={title} getCourses={getCourses} />}
            {activeButton === 'createLesson' && <CreateLesson parentId={parentId} title={title} getCourses={getCourses} />}
        </Box>
    );
};

export default CourseMenuBar;
