import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import EditField from "../../interface/EditField";
import SendTopicComment from "./SendTopicComment";
import ViewTopicComments from "./ViewTopicComments";
import {useSessionData} from "../../../auth/SessionDataProvider";
import StringAvatar from "../../../user/StringAvatar";
import UserAvatar from "../../../user/UserAvatar";

const TopicBubble = ({ topic, setTextToSpeech, colorPalette, hexToShadow }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [topicContent, setTopicContent] = useState('');
    const { sessionData, isOwner, hasEditRights } = useSessionData();

    useEffect(() => {
        setTopicContent(topic.content);
    }, [topic]);

    return (
    <>
        <Box
            className={`chat-bubble`}
            sx={{
                boxShadow: hexToShadow(colorPalette.shadow),
            }}
        >
            <Box
                className="article_box_header_link"
                bgcolor={isOwner(topic) ? colorPalette.paper : colorPalette.secondary}
            >
                {isEditing === true ? (
                    <EditField
                        fieldType={'topic'}
                        fieldId={topic.id}
                        content={topicContent}
                        setContent={setTopicContent}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                    />
                ) : (
                    <>
                        <div dangerouslySetInnerHTML={{__html: topicContent}} style={{ color: isOwner(topic) ? colorPalette.text : colorPalette.text_on_secondary }}></div>
                    </>
                )}
            </Box>
            <Box className="article_box_meta" bgcolor={colorPalette.dark}>
                <Grid container>
                    <Grid item flex={6}>
                        <Box className="article_box_author_link" color={colorPalette.text_on_dark}>
                            <UserAvatar user={sessionData.session_users[topic.user_id]} />
                        </Box>
                        <Box className="article_box_time" color={colorPalette.text_on_dark}>{topic.updated_at}</Box>
                    </Grid>
                    <Grid item flex={2} align={"right"}>
                        {hasEditRights(topic) && (
                            <Button
                                sx={{ ml: 2, mb: 2, mr: 2 }}
                                variant="outlined"
                                color={isEditing === true ? 'error' : 'info'}
                                onClick={() => setIsEditing(!isEditing)}
                                endIcon={<EditIcon sx={{ mr: 2 }}/>}
                            >
                                Szerkesztés
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{m: 2, background: 'none'}}>
                <SendTopicComment topicId={topic.id} sessionData={sessionData} colorPalette={colorPalette} hexToShadow={hexToShadow} />
            </Box>
        </Box>
        <Box>
            <ViewTopicComments topicId={topic.id} setTextToSpeech={setTextToSpeech} colorPalette={colorPalette} sessionData={sessionData} hexToShadow={hexToShadow} />
        </Box>
    </>
    );

};

export default TopicBubble;
