import React, { useRef } from 'react';
import Button from '@mui/material/Button';

const CustomFileInput = ({ handleImageChange, customText }) => {
    const hiddenFileInput = useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const handleFileChange = (e) => {
        handleImageChange(e);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        // Handle the dropped file
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const imageData = event.target.result;
                handleImageChange({ target: { files: [dataURLtoFile(imageData, file.name)] } });
            };
            reader.readAsDataURL(file);
        }
    };

    // Function to convert data URL to a File
    const dataURLtoFile = (dataUrl, fileName) => {
        const arr = dataUrl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], fileName, { type: mime });
    };

    return (
        <div
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            style={{
                width: '100%',
                border: '2px dashed #2196f3',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleFileChange}
                style={{ display: 'none' }}
                accept="image/*"
            />
            <Button color="info" fullWidth sx={{ p: 2 }} onClick={handleClick}>
                {customText}
            </Button>
        </div>
    );
};

export default CustomFileInput;
