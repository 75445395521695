import React, { useState } from 'react';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import SendIcon from "@mui/icons-material/Send";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import axios from "axios";
import {CircularProgress, IconButton, Tooltip} from "@mui/material";
import Grid from "@mui/material/Grid";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from '@mui/icons-material/VolumeOff';

export default function AiChat({ colorPalette, setTextToSpeech }) {
    const [input, setInput] = useState('');
    const [chatLog, setChatLog] = useState([]);
    const [lastQuestion, setLastQuestion] = useState('');
    const [lastAnswer, setLastAnswer] = useState('');
    const [autoPlay, setAutoPlay] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const openAIAnswer = async (question) => {
        setIsLoading(true);
        setChatLog(prevData => [
            ...prevData,
            { text: question, isQuestion: true },
        ]);
        setInput('');

        const formData = new FormData();
        formData.append('question', question);
        formData.append('lastQuestion', lastQuestion);
        formData.append('lastAnswer', lastAnswer);

        axios.post('/api/openai/chat', formData)
            .then(function (response) {
                if (response.data !== 'error') {
                    setChatLog(prevData => [
                        ...prevData,
                        { text: response.data, isQuestion: false },
                    ]);
                    setLastQuestion(question);
                    setLastAnswer(response.data);
                    if (autoPlay === true) {
                        setTextToSpeech(response.data);
                    }
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            });
    };

    const handleSubmit = async () => {
        if (input.trim() !== '') {
            if (isLoading === false) {
                await openAIAnswer(input);
            }
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && input.trim() !== '') {
            handleSubmit();
        }
    };

    const handleChange = (event) => {
        setInput(event.target.value);
    }

    return (
        <Box
            className={`sidebar-profile-container`}
            bgcolor={colorPalette.paper}
            sx={{ mb: 2 }}
        >
            <Box className="chat-bubble-meta" bgcolor={colorPalette.dark}>
                <Grid container>
                    <Grid item xs={11}>
                        <Typography variant={"h6"} color={colorPalette.text_on_dark}><SmartToyIcon sx={{ mr: 2 }}/> Kérdezd a robotot!</Typography>
                    </Grid>
                    <Grid item xs={1} align={"right"}>
                        <Tooltip title={autoPlay ? 'Válasz felolvasása' : 'Hang kikapcsolva'}>
                            <IconButton
                                color={autoPlay ? 'info' : 'error'}
                                onClick={() => setAutoPlay(!autoPlay)}
                            >
                                {autoPlay ? <VolumeUpIcon /> : <VolumeOffIcon />}
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Box>
            <Box color={colorPalette.text} style={{ maxHeight: '500px', overflowY: 'auto' }}>
                <Box sx={{ padding: '20px' }}>
                    Üdvözöllek az AI Chatben!
                </Box>
                {chatLog.map((item, index) => (
                    <Box key={index}
                         className={"chat-bubble"}
                         bgcolor={item.isQuestion ? colorPalette.paper : colorPalette.secondary}
                         color={item.isQuestion ? colorPalette.text : colorPalette.text_on_secondary}
                         sx={{ m: 1, p: 2, borderRadius: '15px' }}
                         align={item.isQuestion ? "right" : "justify"}
                    >
                        {item.text}
                    </Box>
                ))}
            </Box>
            <Box bgcolor={colorPalette.dark} style={{ display: 'flex' }}>
                <TextField
                    fullWidth
                    id="question"
                    label="Kérdés"
                    value={input}
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                    inputProps={{ maxLength: 200 }}
                />
                <Button
                    type="submit"
                    variant="outlined"
                    color="success"
                    disabled={isLoading}
                    onClick={handleSubmit}
                >
                    {isLoading ? <CircularProgress/> : <SendIcon/>}
                </Button>
            </Box>
        </Box>
    );
}
