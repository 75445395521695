import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import EditIcon from '@mui/icons-material/Edit';
import {Link, useLocation } from 'react-router-dom';
import AddIcon from "@mui/icons-material/Add";
import CreateTopic from "../topics/CreateTopic";
import ChatIcon from '@mui/icons-material/Chat';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {Tooltip} from "@mui/material";
import {useSessionData} from "../../../auth/SessionDataProvider";
import AddMemberToGroup from "../AddMemberToGroup";

const GroupMenuBar = ({ groupId, getMembers = () => {}, colorPalette, hexToShadow }) => {
    const [activeButton, setActiveButton] = useState(null);
    const { isOnPath } = useSessionData();
    const location = useLocation();

    const toggleButton = (buttonName) => {
        setActiveButton(activeButton === buttonName ? null : buttonName);
    };

    return (
        <Box
            className={'animated-resize-height'}
            sx={{
                boxShadow: hexToShadow(colorPalette.sidebar),
                height: activeButton ? '420px' : '70px',
                marginBottom: '20px',
            }}
        >
            <Tooltip title={"Vissza a csoportokhoz"} arrow>
            <Button
                color={"info"}
                variant={isOnPath("/view/group") ? "contained" : "outlined"}
                component={Link}
                to={`/view/group/${groupId}`}
                sx={{ ml: 2, mr: 2, mb: 2, mt: 2 }}
            >
                <RemoveRedEyeIcon/>
            </Button>
            </Tooltip>
            <Button
                color={"info"}
                variant={activeButton === 'newTopic' ? "contained" : "outlined"}
                onClick={() => toggleButton('newTopic')}
                sx={{ mr: 2, mb: 2, mt: 2 }}
                endIcon={<ChatIcon/>}
            >
                Új Téma
            </Button>
            <Button
                color={"info"}
                variant={activeButton === 'addMember' ? "contained" : "outlined"}
                onClick={() => toggleButton('addMember')}
                sx={{ mr: 2, mb: 2, mt: 2 }}
                endIcon={<AddIcon/>}
            >
                Tag hozzáadás
            </Button>
            <Button
                color={"info"}
                variant={location.pathname === `/edit/group/${groupId}` ? "contained" : "outlined"}
                component={Link}
                to={`/edit/group/${groupId}`}
                sx={{ mr: 2, mb: 2, mt: 2 }}
                endIcon={<EditIcon/>}
            >
                Beállítások
            </Button>
            <Button
                color={"info"}
                variant={location.pathname.includes('/order') ? "contained" : "outlined"}
                component={Link}
                to={`/edit/group/order/${groupId}`}
                sx={{ mr: 2, mb: 2, mt: 2 }}
                endIcon={<EditIcon/>}
            >
                Tanmenet
            </Button>
            <Button
                color={"info"}
                variant={location.pathname.includes('/members') ? "contained" : "outlined"}
                component={Link}
                to={`/view/group/members/${groupId}`}
                sx={{ mr: 2, mb: 2, mt: 2 }}
                endIcon={<EditIcon/>}
            >
                Tagok
            </Button>
            <Box>
                {activeButton === 'newTopic' && <CreateTopic groupId={groupId} />}
                {activeButton === 'addMember' && <AddMemberToGroup groupId={groupId} getMembers={getMembers} />}
            </Box>
        </Box>
    );
};

export default GroupMenuBar;
