import axios from 'axios';
import React, {useEffect} from 'react';
import GoogleSignInButton from "./GoogleSignInButton";
import {useNavigate} from "react-router-dom";

const SignInWithGoogle=()=>{
    const navigate = useNavigate();

    useEffect(() => {
        window.handleCredentialResponse = function (response) {
            const googleData = JSON.parse(decodeURIComponent(escape(atob(response.credential.split('.')[1]))));
            console.log(googleData);
            axios.post(`/api/user/google_login`,googleData)
                .then((result)=>{
                    if (result.data !== false && result.data.logged === true) {
                        if (result.data.registration === true) {
                            window.location.href = '/register';
                        } else {
                            window.location.href = '/home';
                        }
                    }
                });
        };
    }, []);

    return(
        <GoogleSignInButton/>
    )
}

export default SignInWithGoogle;
