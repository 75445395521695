import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import axios from "axios";
import {CircularProgress} from "@mui/material";
import TitleBox from "../interface/TitleBox";
import CourseStructureEditorElement from "./CourseStructureEditorElement";

export default function CourseStructureEditor({ id = 0, groupId, getOrder, colorPalette, hexToShadow }) {
    const [parentId, setParentId] = useState(id);
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [course, setCourse] = useState([]);

    const getChilds = () => {
        setIsLoading(true);
        axios
            .get(`/api/get/courseItems/${parentId}`)
            .then(function (response) {
                console.log(response.data);
                setItems(response.data);
                setIsLoading(false);
            })
            .catch(function (error) {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
    };

    function getCourse() {
        axios.get(`/api/get/course/${parentId}`).then(function (response) {
            setCourse(response.data);
        });
    }

    useEffect(() => {
        getCourse();
        getChilds();
    }, [parentId]);

    if (isLoading) {
        return (
            <Grid>
                <TitleBox title={parentId === 0 ? "Tananyagok" : course.title} />
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <>
            <Grid>
                <TitleBox title={parentId === 0 ? "Válaszd ki a tananyagokat:" : course.title} />
            </Grid>
            <Grid container>
                <Grid item xs align={"left"} sx={{ mb: 2, mt: 2, mr: 2 }}>
                    {items.map((item, index) => (
                        <CourseStructureEditorElement
                            key={index}
                            item={item}
                            setParentId={setParentId}
                            groupId={groupId}
                            getOrder={getOrder}
                        />
                    ))}
                </Grid>
            </Grid>
        </>
    );
}
