import React, { useState } from 'react';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import axios from "axios";
import ShowSnack from "../../util/ShowSnack";
import Divider from "@mui/material/Divider";
import {useNavigate} from "react-router-dom";

function CreateCategory()
{
    const [inputs, setInputs] = useState([]);
    const [snackOpen, setSnackOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const navigate = useNavigate();

    const handleSubmit = async(e) => {
        e.preventDefault();
        const formData = new FormData();
        for (const [key, value] of Object.entries(inputs)) {
            formData.append(key, value);
        }
        axios.post('/api/create/group', formData)
            .then(function (response) {
                if (response.data >= 0) {
                    setSnackOpen(true);
                    setMessage('Csoport létrehozva!');
                    setSeverity('success');
                    setInputs([]);
                    navigate(`/group/edit/${response.data}`);
                } else {
                    setSnackOpen(true);
                    setMessage('Sikertelen művelet!');
                    setSeverity('error');
                }
            });
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }));
    }

    return (
    <>
        <Divider />
        <Container>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <TextField
                    fullWidth
                    id="title"
                    label="Csoport neve"
                    name="title"
                    onChange={handleChange}
                />
                <Button
                    color={"success"}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 1, mb: 2 }}
                >
                    Létrehozás
                </Button>
            </Box>
        </Container>
        <ShowSnack snackOpen={snackOpen} setSnackOpen={setSnackOpen} message={message} severity={severity} />
    </>
    );
}

export default CreateCategory;
